import { MapOf } from "data/util";
import { StringParser, StringType, TypedString, TypedStringSingle } from "../text";
import { RouteAssembly, SplitType } from "../types";
import { CompilerPresetModule } from "./Module";

export enum KorokType {
	// Korok types (generated)
	// GENERATE CODE
	Acorn = "Acorn",
	AcornFlying = "Acorn Flying",
	AcornLog = "Acorn Log",
	AcornTree = "Acorn Tree",
	AcornHanging = "Acorn Hanging",
	Apple = "Apple",
	Balloon = "Balloon",
	Basketball = "Basketball",
	Beard = "Beard",
	BlockPuzzle = "Block Puzzle",
	BoulderCircle = "Boulder Circle",
	BoulderGolf = "Boulder Golf",
	Confetti = "Confetti",
	FlowerChase = "Flower Chase",
	FlowerCount = "Flower Count",
	IceBlock = "Ice Block",
	JumpFence = "Jump Fence",
	LiftRock = "Lift Rock",
	LiftRockDoor = "Lift Rock (Door)",
	LiftRockTree = "Lift Rock (Tree)",
	LiftRockRubble = "Lift Rock (Rubble)",
	LiftRockBoulder = "Lift Rock (Boulder)",
	LiftRockLeaves = "Lift Rock (Leaves)",
	LiftRockSlab = "Lift Rock (Slab)",
	LightChase = "Light Chase",
	LilyPads = "Lily Pads",
	LuminousStone = "Luminous Stone",
	MatchTree = "Match Tree",
	MatchCactus = "Match Cactus",
	MetalBoxCircle = "Metal Box Circle",
	OfferApple = "Offer Apple",
	OfferBanana = "Offer Banana",
	OfferDurian = "Offer Durian",
	OfferEgg = "Offer Egg",
	OfferPalmFruit = "Offer Palm Fruit",
	OfferPepper = "Offer Pepper",
	OfferShield = "Offer Shield",
	Race = "Race",
	RockCircle = "Rock Circle",
	ShootEmblem = "Shoot Emblem",
	SnowballGolf = "Snowball Golf",
	Torch = "Light Torch",
	TreeBranch = "Tree Branch",
	TreeStump = "Tree Stump",
	Well = "Well",
	Other = "Korok",
}

class KorokModule implements CompilerPresetModule {
	private map: MapOf<() => RouteAssembly> = {};

	constructor(){
		// Add koroks
		// GENERATE CODE
		this.addKorok("A01", [3075.87, 318.83, -3684.38], KorokType.LiftRockTree, "");
		this.addKorok("A02", [3326.96, 309.81, -3589.20], KorokType.RockCircle, "");
		this.addKorok("A03", [3455.61, 314.14, -3581.87], KorokType.LiftRockBoulder, "");
		this.addKorok("A04", [3813.45, 255.99, -3419.22], KorokType.FlowerCount, "");
		this.addKorok("A05", [3152.59, 170.16, -3328.34], KorokType.Race, "");
		this.addKorok("A06", [3496.16, 255.92, -3374.29], KorokType.LiftRockSlab, "");
		this.addKorok("A07", [3779.70, 275.42, -3361.51], KorokType.BoulderGolf, "");
		this.addKorok("A08", [4422.96, 115.15, -3340.21], KorokType.LiftRockRubble, "");
		this.addKorok("A09", [4815.50, 105.73, -3300.24], KorokType.LiftRockSlab, "");
		this.addKorok("A10", [3178.98, 176.40, -3073.66], KorokType.LiftRockSlab, "");
		this.addKorok("A11", [3350.10, 175.02, -3079.83], KorokType.LiftRockTree, "");
		this.addKorok("A12", [3572.84, 205.91, -3046.93], KorokType.Balloon, "Hanging under cliff");
		this.addKorok("A13", [3995.79, 292.52, -3147.58], KorokType.LiftRockLeaves, "");
		this.addKorok("A14", [4344.05, 363.38, -3178.36], KorokType.Race, "");
		this.addKorok("A15", [4428.83, 352.26, -3182.90], KorokType.LiftRock, "");
		this.addKorok("A16", [4503.65, 379.21, -3164.38], KorokType.Confetti, "");
		this.addKorok("A17", [4817.19, 106.12, -3094.21], KorokType.FlowerCount, "Start top of guardian");
		this.addKorok("A18", [3736.66, 147.37, -2884.98], KorokType.AcornLog, "");
		this.addKorok("A19", [4103.20, 232.56, -2832.40], KorokType.FlowerCount, "");
		this.addKorok("A20", [3436.59, 155.19, -2533.65], KorokType.BlockPuzzle, "");
		this.addKorok("A21", [3726.79, 127.29, -2677.46], KorokType.LiftRock, "Side of spring");
		this.addKorok("A22", [4008.60, 195.20, -2736.30], KorokType.LiftRockLeaves, "");
		this.addKorok("A23", [4396.83, 224.35, -2721.58], KorokType.RockCircle, "");
		this.addKorok("A24", [4508.73, 106.40, -2643.60], KorokType.BlockPuzzle, "");
		this.addKorok("A25", [3372.04, 271.94, -2315.19], KorokType.Confetti, "Top of tree");
		this.addKorok("A26", [3732.84, 139.54, -2423.21], KorokType.BlockPuzzle, "");
		this.addKorok("A27", [3877.54, 178.84, -2371.86], KorokType.LightChase, "");
		this.addKorok("A28", [4181.04, 220.80, -2540.40], KorokType.Race, "");
		this.addKorok("A29", [4338.09, 170.55, -2438.51], KorokType.Balloon, "");
		this.addKorok("A30", [3244.91, 265.87, -2000.30], KorokType.LightChase, "");
		this.addKorok("A31", [3479.96, 280.77, -2077.34], KorokType.LiftRockLeaves, "");
		this.addKorok("A32", [3662.78, 273.67, -2067.31], KorokType.MatchTree, "");
		this.addKorok("A33", [4530.94, 106.98, -2098.37], KorokType.LiftRockLeaves, "");
		this.addKorok("A34", [4098.31, 232.81, -1756.46], KorokType.RockCircle, "");
		this.addKorok("A35", [4634.40, 139.70, -1840.94], KorokType.FlowerChase, "");
		this.addKorok("A36", [4691.70, 136.71, -1777.33], KorokType.BlockPuzzle, "");
		this.addKorok("A37", [3709.39, 204.17, -1683.44], KorokType.LiftRock, "Top of stone structure");
		this.addKorok("A38", [4095.78, 220.00, -1627.46], KorokType.FlowerChase, "On bridge");
		this.addKorok("A39", [3297.69, 466.47, -1583.79], KorokType.LiftRockDoor, "");
		this.addKorok("A40", [3288.67, 511.29, -1574.58], KorokType.LiftRockLeaves, "");
		this.addKorok("A41", [3237.79, 479.93, -1527.74], KorokType.LiftRockLeaves, "");
		this.addKorok("A42", [3292.11, 530.90, -1428.61], KorokType.LiftRock, "Next to cannons");
		this.addKorok("A43", [3296.23, 459.86, -1421.08], KorokType.LiftRockLeaves, "");
		this.addKorok("A44", [4356.77, 284.96, -1528.09], KorokType.Race, "");
		this.addKorok("A45", [3112.55, 426.00, -1295.32], KorokType.RockCircle, "");
		this.addKorok("A46", [3407.36, 442.76, -1278.32], KorokType.LiftRockDoor, "");
		this.addKorok("A47", [3882.54, 356.53, -1340.01], KorokType.OfferApple, "");
		this.addKorok("A48", [4104.55, 282.08, -1350.66], KorokType.FlowerCount, "");
		this.addKorok("A49", [4038.20, 329.62, -1254.87], KorokType.MatchTree, "");
		this.addKorok("A50", [3010.15, 346.43, -1204.24], KorokType.LiftRockSlab, "");
		this.addKorok("A51", [3726.30, 331.25, -1129.71], KorokType.Basketball, "");
		this.addKorok("A52", [3604.77, 334.48, -1008.63], KorokType.LiftRock, "Below bridge");
		this.addKorok("A53", [4239.35, 409.88, -998.25], KorokType.MatchTree, "");
		this.addKorok("A54", [3960.61, 378.52, -858.58], KorokType.Balloon, "");
		this.addKorok("A55", [4042.21, 351.56, -661.96], KorokType.LiftRockBoulder, "");
		this.addKorok("A56", [4332.91, 406.96, -696.63], KorokType.LightChase, "");
		this.addKorok("A57", [4144.26, 406.69, -595.96], KorokType.Confetti, "Top of tree");
		this.addKorok("C01", [-753.67, 151.17, -1086.79], KorokType.LiftRock, "");
		this.addKorok("C02", [589.77, 121.48, -1107.12], KorokType.BlockPuzzle, "");
		this.addKorok("C03", [-670.08, 119.11, -932.68], KorokType.BlockPuzzle, "");
		this.addKorok("C04", [178.54, 134.04, -940.02], KorokType.LiftRock, "In fountain");
		this.addKorok("C05", [319.65, 137.19, -962.06], KorokType.AcornTree, "In tree");
		this.addKorok("C06", [506.90, 141.81, -882.61], KorokType.LilyPads, "");
		this.addKorok("C07", [-941.41, 133.35, -817.63], KorokType.AcornFlying, "");
		this.addKorok("C08", [-681.31, 122.38, -860.62], KorokType.OfferShield, "");
		this.addKorok("C09", [696.30, 217.99, -824.38], KorokType.Confetti, "Top of tree");
		this.addKorok("C10", [292.18, 117.21, -724.08], KorokType.Basketball, "");
		this.addKorok("C11", [613.41, 183.51, -743.37], KorokType.LiftRock, "Inside hollow tree");
		this.addKorok("C12", [840.40, 236.28, -692.47], KorokType.LiftRock, "In tree stump on peak");
		this.addKorok("C13", [1265.17, 116.40, -707.25], KorokType.Basketball, "");
		this.addKorok("C14", [-935.20, 167.84, -641.24], KorokType.LiftRock, "");
		this.addKorok("C15", [-609.56, 119.00, -682.30], KorokType.LilyPads, "");
		this.addKorok("C16", [-267.21, 139.86, -610.18], KorokType.Acorn, "Under bridge");
		this.addKorok("C17", [85.54, 138.13, -634.97], KorokType.LiftRockLeaves, "");
		this.addKorok("C18", [396.08, 115.50, -625.56], KorokType.LightChase, "");
		this.addKorok("C19", [686.03, 167.93, -635.10], KorokType.Balloon, "Pinwheel on hollow tree");
		this.addKorok("C20", [-763.77, 156.04, -606.27], KorokType.LiftRockRubble, "");
		this.addKorok("C21", [-796.21, 187.66, -558.85], KorokType.RockCircle, "");
		this.addKorok("C22", [-666.93, 128.60, -543.81], KorokType.RockCircle, "");
		this.addKorok("C23", [504.45, 123.33, -493.46], KorokType.AcornHanging, "Below bridge");
		this.addKorok("C24", [-1035.08, 150.22, -446.22], KorokType.LiftRock, "On small hill near bridge");
		this.addKorok("C25", [-803.95, 138.07, -403.12], KorokType.LiftRock, "");
		this.addKorok("C26", [-663.30, 146.77, -432.60], KorokType.Balloon, "In tree");
		this.addKorok("C27", [-253.82, 141.72, -425.72], KorokType.Race, "");
		this.addKorok("C28", [86.23, 138.81, -412.08], KorokType.LiftRockDoor, "");
		this.addKorok("C29", [97.63, 181.01, -429.80], KorokType.Confetti, "Top of flagpole");
		this.addKorok("C30", [-886.65, 129.25, -274.04], KorokType.LiftRockRubble, "Next to broken house");
		this.addKorok("C31", [-548.5, 140.88, -229.52], KorokType.LiftRock, "On wall");
		this.addKorok("C32", [-254.35, 146.84, -264.41], KorokType.ShootEmblem, "Castle Town Gate");
		this.addKorok("C33", [-161.75, 147.43, -262.04], KorokType.Balloon, "On outer wall");
		this.addKorok("C34", [623.24, 123.53, -194.76], KorokType.MatchTree, "");
		this.addKorok("C35", [-239.38, 139.77, -112.59], KorokType.LiftRock, "On pillar");
		this.addKorok("C36", [-1336.88, 122.55, 249.66], KorokType.LiftRock, "");
		this.addKorok("C37", [-1336.35, 117.12, 366.61], KorokType.BlockPuzzle, "");
		this.addKorok("C38", [-1155.96, 137.33, 245.12], KorokType.FlowerChase, "");
		this.addKorok("C39", [-868.50, 187.17, 219.11], KorokType.Confetti, "Top of flag pole");
		this.addKorok("C40", [-728.06, 154.69, 134.77], KorokType.LightChase, "");
		this.addKorok("C41", [-710.25, 158.35, 99.93], KorokType.AcornTree, "");
		this.addKorok("C42", [-516.95, 154.04, 97.79], KorokType.Balloon, "");
		this.addKorok("C43", [-427.28, 135.84, 150.92], KorokType.AcornFlying, "");
		this.addKorok("C44", [25.66, 148.47, 147.48], KorokType.LiftRock, "");
		this.addKorok("C45", [384.26, 154.17, 135.36], KorokType.LiftRock, "On hill");
		this.addKorok("C46", [806.57, 116.89, 24.09], KorokType.LiftRock, "Under bridge");
		this.addKorok("C47", [-1127.28, 145.96, 414.31], KorokType.LilyPads, "");
		this.addKorok("C48", [-737.40, 125.85, 333.75], KorokType.LiftRock, "Between 2 rocks");
		this.addKorok("C49", [-363.24, 131.73, 396.94], KorokType.AcornHanging, "Wagon next to tree");
		this.addKorok("C50", [142.93, 128.21, 296.02], KorokType.FlowerCount, "Chaos Ranch");
		this.addKorok("C51", [627.03, 130.94, 182.30], KorokType.BoulderCircle, "");
		this.addKorok("C52", [-1313.94, 116.17, 622.86], KorokType.LiftRock, "In well");
		this.addKorok("C53", [-1020.38, 201.02, 559.81], KorokType.OfferApple, "");
		this.addKorok("C54", [-512.75, 170.26, 550.62], KorokType.Confetti, "Top of flagpole");
		this.addKorok("C55", [-122.09, 128.79, 594.00], KorokType.LightChase, "");
		this.addKorok("C56", [322.06, 127.50, 525.00], KorokType.OfferApple, "");
		this.addKorok("C57", [-1249.55, 125.52, 730.67], KorokType.AcornTree, "");
		this.addKorok("C58", [-1439.60, 125.50, 846.66], KorokType.TreeStump, "");
		this.addKorok("C59", [-1512.68, 117.38, 908.66], KorokType.Basketball, "");
		this.addKorok("C60", [-1106.93, 139.42, 905.26], KorokType.BlockPuzzle, "");
		this.addKorok("C61", [-1009.46, 130.11, 932.03], KorokType.RockCircle, "");
		this.addKorok("C62", [-749.92, 132.90, 892.21], KorokType.LightChase, "");
		this.addKorok("C63", [-644.58, 146.21, 876.11], KorokType.FlowerChase, "");
		this.addKorok("C64", [-133.70, 143.35, 945.03], KorokType.LightChase, "");
		this.addKorok("C65", [225.38, 173.64, 872.04], KorokType.AcornTree, "");
		this.addKorok("C66", [-1356.69, 140.95, 1002.41], KorokType.RockCircle, "");
		this.addKorok("C67", [-1154.35, 132.86, 964.63], KorokType.LiftRock, "On ledge (hard to see)");
		this.addKorok("C68", [-1134.46, 143.84, 1030.47], KorokType.AcornHanging, "On bridge");
		this.addKorok("C69", [-599.94, 138.02, 1071.81], KorokType.AcornHanging, "From tree");
		this.addKorok("C70", [-106.45, 153.33, 1047.96], KorokType.RockCircle, "");
		this.addKorok("C71", [39.26, 140.77, 1034.49], KorokType.LiftRockTree, "");
		this.addKorok("C72", [-1370.34, 174.22, 1165.82], KorokType.AcornFlying, "");
		this.addKorok("C73", [-1190.02, 174.95, 1116.74], KorokType.Race, "");
		this.addKorok("C74", [-1149.91, 218.28, 1204.10], KorokType.LiftRock, "Top of Coliseum");
		this.addKorok("C75", [-425.27, 131.0, 1189.34], KorokType.LilyPads, "");
		this.addKorok("C76", [-466.41, 137.21, 1279.38], KorokType.LiftRockLeaves, "");
		this.addKorok("C77", [295.58, 135.22, 1204.79], KorokType.Balloon, "");
		this.addKorok("C78", [-1314.29, 139.19, 1257.33], KorokType.LiftRock, "");
		this.addKorok("C79", [-1224.68, 299.79, 1358.41], KorokType.LiftRock, "");
		this.addKorok("C80", [-1280.39, 284.68, 1384.89], KorokType.AcornLog, "");
		this.addKorok("C81", [-1057.31, 167.53, 1327.52], KorokType.LightChase, "In ruins");
		this.addKorok("C82", [-770.02, 135.06, 1380.97], KorokType.LightChase, "");
		this.addKorok("C83", [-653.64, 159.13, 1390.28], KorokType.Balloon, "");
		this.addKorok("C84", [69.30, 121.17, 1438.00], KorokType.AcornFlying, "");
		this.addKorok("C85", [-1666.66, 249.27, 1393.96], KorokType.LiftRockTree, "");
		this.addKorok("C86", [-1728.58, 144.39, 1478.86], KorokType.LiftRock, "");
		this.addKorok("C87", [-1583.93, 68.69, 1579.69], KorokType.Basketball, "");
		this.addKorok("C88", [-1746.91, 71.0, 1831.09], KorokType.LilyPads, "");
		this.addKorok("C89", [-1491.53, 68.49, 1832.24], KorokType.Basketball, "");
		this.addKorok("D01", [954.40, 116.27, 942.46], KorokType.LiftRock, "Island below");
		this.addKorok("D02", [1775.80, 219.04, 963.60], KorokType.LilyPads, "Next to Impa's house");
		this.addKorok("D03", [1807.03, 220.44, 992.20], KorokType.OfferApple, "");
		this.addKorok("D04", [1793.89, 258.89, 1052.82], KorokType.LiftRock, "Ledge");
		this.addKorok("D05", [1798.33, 230.96, 1078.17], KorokType.ShootEmblem, "");
		this.addKorok("D06", [1957.25, 248.06, 1062.05], KorokType.ShootEmblem, "");
		this.addKorok("D07", [2163.89, 460.62, 1034.73], KorokType.LiftRock, "");
		this.addKorok("D08", [1939.51, 269.11, 1110.93], KorokType.LiftRock, "On ledge");
		this.addKorok("D09", [1676.10, 324.63, 1157.93], KorokType.MatchTree, "");
		this.addKorok("D10", [1739.30, 413.37, 1186.67], KorokType.LiftRock, "");
		this.addKorok("D11", [1933.25, 376.73, 1220.96], KorokType.LiftRock, "Peak");
		this.addKorok("D12", [2058.50, 244.81, 1208.55], KorokType.BlockPuzzle, "");
		this.addKorok("D13", [516.10, 127.02, 1167.10], KorokType.LiftRockTree, "");
		this.addKorok("D14", [337.14, 120.05, 1271.06], KorokType.AcornHanging, "From bridge");
		this.addKorok("D15", [715.11, 166.34, 1327.02], KorokType.TreeStump, "Edge of forest");
		this.addKorok("D16", [1040.55, 121.35, 1318.15], KorokType.LiftRock, "Small island");
		this.addKorok("D17", [1293.44, 273.69, 1331.36], KorokType.LiftRockTree, "");
		this.addKorok("D18", [1553.54, 119.0, 1297.18], KorokType.LilyPads, "");
		this.addKorok("D19", [1638.96, 116.47, 1376.28], KorokType.Basketball, "");
		this.addKorok("D20", [1750.43, 116.39, 1544.68], KorokType.Basketball, "");
		this.addKorok("D21", [2207.28, 192.59, 1373.48], KorokType.Confetti, "Top of tree");
		this.addKorok("D22", [318.15, 115.38, 1397.90], KorokType.LiftRockBoulder, "");
		this.addKorok("D23", [518.62, 131.00, 1475.52], KorokType.AcornFlying, "");
		this.addKorok("D24", [668.64, 135.47, 1468.29], KorokType.BoulderGolf, "");
		this.addKorok("D25", [788.83, 145.87, 1471.35], KorokType.JumpFence, "");
		this.addKorok("D26", [87.01, 164.54, 1585.09], KorokType.Confetti, "Top of flagpole");
		this.addKorok("D27", [232.20, 115.35, 1601.41], KorokType.LiftRock, "Under bridge");
		this.addKorok("D28", [671.09, 180.86, 1638.37], KorokType.Confetti, "Top of flagpole");
		this.addKorok("D29", [850.50, 115.39, 1623.99], KorokType.LiftRock, "On island");
		this.addKorok("D30", [1376.52, 327.85, 1680.34], KorokType.OfferApple, "");
		this.addKorok("D31", [54.98, 146.90, 1758.55], KorokType.BoulderGolf, "");
		this.addKorok("D32", [112.31, 116.67, 1709.78], KorokType.Basketball, "");
		this.addKorok("D33", [178.96, 116.68, 1719.98], KorokType.BlockPuzzle, "");
		this.addKorok("D34", [225.30, 121.99, 1705.66], KorokType.LiftRock, "");
		this.addKorok("D35", [312.68, 180.27, 1766.79], KorokType.Balloon, "");
		this.addKorok("D36", [516.76, 167.24, 1788.16], KorokType.FlowerChase, "");
		this.addKorok("D37", [644.18, 116.81, 1763.26], KorokType.BoulderGolf, "");
		this.addKorok("D38", [704.98, 116.75, 1796.56], KorokType.LiftRock, "In cave");
		this.addKorok("D39", [1049.23, 115.87, 1783.27], KorokType.FlowerChase, "Across river");
		this.addKorok("D40", [1258.45, 492.11, 1851.12], KorokType.LiftRock, "");
		this.addKorok("D41", [1517.27, 400.24, 1850.08], KorokType.BoulderGolf, "");
		this.addKorok("D42", [1748.57, 140.69, 1921.65], KorokType.Confetti, "Top of stable");
		this.addKorok("D43", [-189.70, 121.28, 1835.39], KorokType.FlowerCount, "In cave");
		this.addKorok("D44", [5.32, 207.82, 1897.89], KorokType.LiftRock, "");
		this.addKorok("D45", [160.04, 150.43, 1942.17], KorokType.Confetti, "");
		this.addKorok("D46", [551.57, 182.90, 1903.50], KorokType.MatchTree, "");
		this.addKorok("D47", [868.27, 142.61, 1902.03], KorokType.Balloon, "");
		this.addKorok("D48", [1101.69, 224.28, 1889.53], KorokType.LiftRock, "Upper level");
		this.addKorok("D49", [1245.11, 541.34, 1942.17], KorokType.OfferApple, "");
		this.addKorok("D50", [815.22, 202.72, 2089.43], KorokType.LiftRockSlab, "");
		this.addKorok("D51", [1877.42, 132.53, 2051.32], KorokType.LiftRockTree, "Big tree");
		this.addKorok("D52", [2054.82, 153.24, 2092.72], KorokType.Balloon, "");
		this.addKorok("D53", [2484.82, 119.0, 2103.39], KorokType.LilyPads, "");
		this.addKorok("D54", [-115.48, 186.84, 2303.39], KorokType.Torch, "");
		this.addKorok("D55", [1682.65, 201.46, 2327.61], KorokType.LiftRock, "On ledge high");
		this.addKorok("D56", [1886.18, 147.40, 2418.73], KorokType.LiftRock, "In tree stump");
		this.addKorok("D57", [2303.51, 281.29, 2375.13], KorokType.LilyPads, "");
		this.addKorok("D58", [2366.56, 221.70, 2303.49], KorokType.AcornFlying, "");
		this.addKorok("D59", [1936.54, 134.27, 2564.50], KorokType.LiftRock, "Bottom of waterfall");
		this.addKorok("E01", [1615.10, 207.95, -3661.03], KorokType.LiftRockLeaves, "");
		this.addKorok("E02", [2087.11, 318.25, -3573.55], KorokType.BlockPuzzle, "");
		this.addKorok("E03", [2233.99, 338.44, -3673.52], KorokType.LiftRockRubble, "");
		this.addKorok("E04", [2551.06, 411.23, -3592.67], KorokType.LiftRockRubble, "");
		this.addKorok("E05", [2662.63, 479.33, -3471.58], KorokType.RockCircle, "Pillars around shrine");
		this.addKorok("E06", [2469.91, 514.72, -3174.50], KorokType.LiftRockRubble, "");
		this.addKorok("E07", [1549.11, 533.40, -3108.54], KorokType.LiftRock, "Top of island");
		this.addKorok("E08", [1632.01, 585.55, -2939.50], KorokType.LiftRock, "Top of northern mine");
		this.addKorok("E09", [2195.50, 613.21, -3007.84], KorokType.LiftRock, "On hanging rock high");
		this.addKorok("E10", [1885.78, 513.93, -2749.10], KorokType.Balloon, "");
		this.addKorok("E11", [2060.97, 580.31, -2757.41], KorokType.RockCircle, "");
		this.addKorok("E12", [3322.94, 379.46, -2803.06], KorokType.BlockPuzzle, "");
		this.addKorok("E13", [1658.13, 554.58, -2564.87], KorokType.LiftRock, "");
		this.addKorok("E14", [1725.34, 607.05, -2553.93], KorokType.Race, "");
		this.addKorok("E15", [1593.87, 543.91, -2475.24], KorokType.Confetti, "Inside Goron's mouth");
		this.addKorok("E16", [1865.55, 533.93, -2465.04], KorokType.Confetti, "Top of skeleton");
		this.addKorok("E17", [1867.81, 511.00, -2400.63], KorokType.Race, "");
		this.addKorok("E18", [1647.23, 521.04, -2244.46], KorokType.RockCircle, "");
		this.addKorok("E19", [1537.85, 505.38, -2091.50], KorokType.Balloon, "High up");
		this.addKorok("E20", [2019.41, 557.72, -2172.89], KorokType.BoulderGolf, "");
		this.addKorok("E21", [2170.73, 547.03, -2109.55], KorokType.BlockPuzzle, "");
		this.addKorok("E22", [2355.87, 524.65, -2067.37], KorokType.LiftRock, "On stone above ring");
		this.addKorok("E23", [2663.89, 564.49, -2066.87], KorokType.LiftRockRubble, "");
		this.addKorok("E24", [1412.02, 408.74, -1759.80], KorokType.BoulderGolf, "");
		this.addKorok("E25", [1544.33, 394.20, -1837.21], KorokType.Race, "");
		this.addKorok("E26", [1687.55, 427.39, -1717.08], KorokType.BoulderGolf, "");
		this.addKorok("E27", [1950.85, 457.49, -1832.65], KorokType.LiftRockRubble, "");
		this.addKorok("E28", [2273.79, 431.76, -1823.22], KorokType.Balloon, "");
		this.addKorok("E29", [2424.33, 253.68, -1738.50], KorokType.Basketball, "");
		this.addKorok("E30", [2703.71, 225.52, -1749.27], KorokType.BlockPuzzle, "");
		this.addKorok("E31", [1426.89, 465.03, -1544.06], KorokType.RockCircle, "");
		this.addKorok("E32", [2266.55, 347.37, -1565.48], KorokType.LiftRockRubble, "");
		this.addKorok("E33", [2407.27, 328.66, -1613.59], KorokType.Race, "");
		this.addKorok("E34", [2751.06, 293.76, -1675.90], KorokType.RockCircle, "");
		this.addKorok("E35", [1377.20, 322.40, -1261.17], KorokType.RockCircle, "");
		this.addKorok("E36", [1940.14, 226.01, -1217.73], KorokType.LilyPads, "");
		this.addKorok("E37", [2179.55, 226.34, -1282.03], KorokType.RockCircle, "");
		this.addKorok("E38", [2478.57, 313.41, -1391.59], KorokType.BlockPuzzle, "");
		this.addKorok("E39", [2752.29, 233.72, -1366.33], KorokType.Basketball, "");
		this.addKorok("E40", [2504.47, 312.20, -1304.28], KorokType.LiftRockRubble, "");
		this.addKorok("E41", [2408.19, 262.34, -1229.95], KorokType.Balloon, "");
		this.addKorok("E42", [2441.13, 257.40, -1241.72], KorokType.LiftRock, "");
		this.addKorok("E43", [2315.02, 293.52, -1133.25], KorokType.LiftRockRubble, "");
		this.addKorok("E44", [2655.78, 261.74, -971.17], KorokType.LiftRock, "");
		this.addKorok("E45", [2275.00, 134.98, -720.14], KorokType.Race, "");
		this.addKorok("F01", [1381.90, 288.30, 2277.44], KorokType.AcornTree, "");
		this.addKorok("F02", [1188.64, 323.43, 2476.70], KorokType.Race, "");
		this.addKorok("F03", [1305.28, 191.34, 2434.05], KorokType.Balloon, "");
		this.addKorok("F04", [1323.72, 194.40, 2453.45], KorokType.RockCircle, "");
		this.addKorok("F05", [1462.67, 336.79, 2370.84], KorokType.Balloon, "");
		this.addKorok("F06", [2181.72, 414.98, 2534.69], KorokType.LiftRock, "");
		this.addKorok("F07", [2403.83, 256.60, 2511.22], KorokType.LightChase, "");
		this.addKorok("F08", [1966.62, 365.96, 2634.47], KorokType.LiftRockTree, "");
		this.addKorok("F09", [2099.72, 363.66, 2625.57], KorokType.Basketball, "");
		this.addKorok("F10", [1373.00, 220.29, 2749.04], KorokType.FlowerChase, "In cave");
		this.addKorok("F11", [1936.52, 492.98, 2778.54], KorokType.LiftRock, "Peak");
		this.addKorok("F12", [2103.91, 422.50, 2726.09], KorokType.FlowerChase, "");
		this.addKorok("F13", [2284.27, 287.53, 2659.97], KorokType.LiftRock, "Under tree");
		this.addKorok("F14", [2874.91, 299.73, 2683.36], KorokType.LilyPads, "");
		this.addKorok("F15", [1325.56, 296.66, 3008.67], KorokType.LightChase, "");
		this.addKorok("F16", [1562.56, 298.62, 2898.31], KorokType.Race, "");
		this.addKorok("F17", [1773.84, 346.68, 2930.86], KorokType.Well, "Ball and chain");
		this.addKorok("F18", [3084.93, 239.77, 2884.64], KorokType.LiftRockRubble, "In cave");
		this.addKorok("F19", [1785.53, 344.36, 3011.07], KorokType.Race, "Swim up");
		this.addKorok("F20", [1943.74, 348.58, 3009.42], KorokType.MatchTree, "");
		this.addKorok("F21", [2294.48, 287.50, 3028.17], KorokType.OfferApple, "");
		this.addKorok("F22", [2951.34, 246.57, 3005.96], KorokType.FlowerChase, "");
		this.addKorok("F23", [1012.06, 127.12, 3258.18], KorokType.FlowerChase, "");
		this.addKorok("F24", [1055.11, 167.13, 3254.77], KorokType.Balloon, "");
		this.addKorok("F25", [1406.71, 255.48, 3198.75], KorokType.OfferDurian, "");
		this.addKorok("F26", [1660.15, 252.65, 3163.64], KorokType.BlockPuzzle, "Middle of waterfall");
		this.addKorok("F27", [1799.42, 224.83, 3157.65], KorokType.LiftRock, "Ledge");
		this.addKorok("F28", [1803.73, 202.60, 3221.99], KorokType.FlowerChase, "");
		this.addKorok("F29", [2032.28, 292.14, 3164.34], KorokType.BlockPuzzle, "Cube in lake");
		this.addKorok("F30", [2171.21, 198.86, 3276.90], KorokType.MatchTree, "");
		this.addKorok("F31", [2636.23, 191.58, 3197.79], KorokType.LiftRockLeaves, "");
		this.addKorok("F32", [3175.44, 309.28, 3204.02], KorokType.LightChase, "");
		this.addKorok("F33", [3639.77, 326.91, 3258.22], KorokType.LiftRock, "On ledge");
		this.addKorok("F34", [1492.11, 188.12, 3370.48], KorokType.Balloon, "");
		this.addKorok("F35", [1857.43, 176.84, 3351.67], KorokType.LuminousStone, "Eye of statue");
		this.addKorok("F36", [2968.71, 122.93, 3380.18], KorokType.RockCircle, "");
		this.addKorok("F37", [3289.57, 175.80, 3338.79], KorokType.LilyPads, "");
		this.addKorok("F38", [1217.38, 135.04, 3524.10], KorokType.LiftRock, "");
		this.addKorok("F39", [1378.09, 256.94, 3503.56], KorokType.OfferDurian, "");
		this.addKorok("F40", [1449.47, 222.69, 3531.36], KorokType.LiftRock, "Middle of mud");
		this.addKorok("F41", [1552.48, 182.81, 3529.24], KorokType.Beard, "");
		this.addKorok("F42", [1733.16, 168.05, 3450.58], KorokType.Balloon, "Middle of bridge");
		this.addKorok("F43", [2139.98, 200.28, 3531.93], KorokType.RockCircle, "");
		this.addKorok("F44", [2836.96, 126.87, 3452.76], KorokType.Apple, "");
		this.addKorok("F45", [2939.43, 122.43, 3427.08], KorokType.FlowerChase, "Top of house");
		this.addKorok("F46", [1840.68, 135.02, 3595.82], KorokType.BlockPuzzle, "");
		this.addKorok("F47", [2559.24, 344.00, 3602.71], KorokType.LiftRock, "Peak");
		this.addKorok("F48", [1757.80, 253.70, 3685.97], KorokType.OfferBanana, "");
		this.addKorok("F49", [2856.58, 113.69, 3651.77], KorokType.RockCircle, "");
		this.addKorok("F50", [3024.40, 107.61, 3654.22], KorokType.FlowerChase, "");
		this.addKorok("F51", [3367.10, 109.90, 3653.53], KorokType.LilyPads, "");
		this.addKorok("F52", [3796.61, 106.29, 3618.89], KorokType.LiftRock, "");
		this.addKorok("F53", [1374.62, 107.24, 3751.46], KorokType.RockCircle, "");
		this.addKorok("F54", [1513.74, 136.27, 3725.03], KorokType.LiftRock, "Ledge beach level");
		this.addKorok("F55", [1821.03, 272.02, 3742.02], KorokType.LiftRock, "Upper ledge");
		this.addKorok("F56", [1625.49, 143.74, 3891.73], KorokType.Balloon, "Under cliff");
		this.addKorok("F57", [2508.15, 157.62, 3825.90], KorokType.RockCircle, "");
		this.addKorok("F58", [3170.41, 109.88, 3824.31], KorokType.LilyPads, "Close to beach rocks");
		this.addKorok("G01", [-4409.10, 618.92, 331.34], KorokType.BoulderGolf, "");
		this.addKorok("G02", [-4183.67, 491.75, 291.06], KorokType.Race, "");
		this.addKorok("G03", [-4066.78, 569.14, 437.84], KorokType.RockCircle, "");
		this.addKorok("G04", [-4355.33, 716.59, 709.87], KorokType.BlockPuzzle, "");
		this.addKorok("G05", [-4325.40, 768.44, 698.29], KorokType.Race, "");
		this.addKorok("G06", [-3673.78, 550.79, 698.30], KorokType.LiftRock, "On wood platform");
		this.addKorok("G07", [-4690.48, 704.14, 842.36], KorokType.IceBlock, "");
		this.addKorok("G08", [-4340.95, 767.38, 782.13], KorokType.FlowerChase, "");
		this.addKorok("G09", [-3688.84, 740.47, 798.72], KorokType.RockCircle, "");
		this.addKorok("G10", [-3069.67, 452.02, 910.74], KorokType.RockCircle, "");
		this.addKorok("G11", [-4210.02, 609.72, 1133.50], KorokType.Race, "");
		this.addKorok("G12", [-3224.51, 570.76, 1022.45], KorokType.BlockPuzzle, "");
		this.addKorok("G13", [-2867.81, 518.54, 1124.92], KorokType.Confetti, "Top of tree");
		this.addKorok("G14", [-2454.27, 337.82, 1157.30], KorokType.Balloon, "");
		this.addKorok("G15", [-4552.23, 623.39, 1353.15], KorokType.LiftRock, "");
		this.addKorok("G16", [-4112.89, 560.66, 1377.53], KorokType.LightChase, "");
		this.addKorok("G17", [-3065.60, 604.49, 1287.26], KorokType.IceBlock, "");
		this.addKorok("G18", [-2850.0, 678.32, 1229.0], KorokType.IceBlock, "");
		this.addKorok("G19", [-2521.76, 457.05, 1318.58], KorokType.RockCircle, "");
		this.addKorok("G20", [-4527.83, 571.97, 1423.32], KorokType.RockCircle, "");
		this.addKorok("G21", [-4180.76, 440.36, 1482.39], KorokType.BlockPuzzle, "");
		this.addKorok("G22", [-3129.01, 614.01, 1467.15], KorokType.RockCircle, "");
		this.addKorok("G23", [-2646.00, 688.32, 1535.13], KorokType.LiftRock, "");
		this.addKorok("G24", [-2081.09, 353.63, 1477.02], KorokType.RockCircle, "");
		this.addKorok("G25", [-4075.58, 298.89, 1595.13], KorokType.OfferBanana, "");
		this.addKorok("G26", [-3715.85, 570.37, 1558.07], KorokType.SnowballGolf, "");
		this.addKorok("G27", [-2817.46, 578.22, 1652.72], KorokType.RockCircle, "Platform on cliff");
		this.addKorok("G28", [-2464.74, 417.71, 1713.47], KorokType.BlockPuzzle, "");
		this.addKorok("G29", [-1799.60, 114.89, 1625.90], KorokType.BlockPuzzle, "");
		this.addKorok("G30", [-4136.39, 401.44, 1717.95], KorokType.RockCircle, "");
		this.addKorok("G31", [-3845.23, 412.26, 1897.05], KorokType.Balloon, "");
		this.addKorok("G32", [-2429.13, 203.48, 1921.33], KorokType.Race, "");
		this.addKorok("G33", [-4170.68, 193.18, 1989.38], KorokType.RockCircle, "");
		this.addKorok("G34", [-3071.38, 197.78, 2044.73], KorokType.BlockPuzzle, "");
		this.addKorok("G35", [-2697.26, 270.84, 2105.06], KorokType.Race, "");
		this.addKorok("G36", [-1721.22, 143.19, 2237.78], KorokType.Well, "");
		this.addKorok("H01", [-4397.60, 332.44, -3773.91], KorokType.BlockPuzzle, "");
		this.addKorok("H02", [-4029.33, 464.85, -3760.17], KorokType.IceBlock, "");
		this.addKorok("H03", [-3960.37, 350.67, -3723.25], KorokType.FlowerChase, "");
		this.addKorok("H04", [-3757.12, 375.46, -3848.08], KorokType.RockCircle, "");
		this.addKorok("H05", [-1970.93, 420.71, -3792.56], KorokType.Balloon, "");
		this.addKorok("H06", [-1375.17, 361.92, -3640.94], KorokType.RockCircle, "");
		this.addKorok("H07", [-4167.16, 516.82, -3615.93], KorokType.Race, "");
		this.addKorok("H08", [-3999.21, 632.96, -3620.06], KorokType.Balloon, "");
		this.addKorok("H09", [-3496.12, 474.21, -3580.67], KorokType.LiftRockLeaves, "");
		this.addKorok("H10", [-3610.64, 561.92, -3491.99], KorokType.AcornLog, "");
		this.addKorok("H11", [-3414.79, 542.02, -3509.61], KorokType.Confetti, "Top of bare tree");
		this.addKorok("H12", [-2842.69, 333.93, -3493.48], KorokType.RockCircle, "");
		this.addKorok("H13", [-2290.10, 564.25, -3407.59], KorokType.IceBlock, "");
		this.addKorok("H14", [-1535.29, 346.88, -3475.81], KorokType.AcornLog, "");
		this.addKorok("H15", [-1095.98, 413.97, -3399.87], KorokType.BlockPuzzle, "");
		this.addKorok("H16", [-872.89, 335.50, -3316.10], KorokType.Balloon, "");
		this.addKorok("H17", [-4155.93, 350.44, -3404.12], KorokType.RockCircle, "Snowy platform");
		this.addKorok("H18", [-3843.15, 435.38, -3348.18], KorokType.Confetti, "Forest with 5 trees");
		this.addKorok("H19", [-3574.57, 494.23, -3272.38], KorokType.Balloon, "");
		this.addKorok("H20", [-2946.50, 686.98, -3146.69], KorokType.Confetti, "Top of house");
		this.addKorok("H21", [-2602.17, 508.35, -3238.93], KorokType.Confetti, "Top of tree");
		this.addKorok("H22", [-1110.79, 438.06, -3043.85], KorokType.BoulderGolf, "");
		this.addKorok("H23", [-4424.15, 497.16, -3215.94], KorokType.LiftRock, "Peak far");
		this.addKorok("H24", [-4230.89, 284.85, -3134.81], KorokType.FlowerChase, "Below high forest");
		this.addKorok("H25", [-3793.73, 353.06, -3032.96], KorokType.LiftRock, "In corner");
		this.addKorok("H26", [-3734.78, 446.58, -2964.39], KorokType.LiftRock, "");
		this.addKorok("H27", [-3350.52, 621.95, -2994.89], KorokType.Balloon, "");
		this.addKorok("H28", [-2783.55, 838.53, -2895.52], KorokType.LiftRock, "");
		this.addKorok("H29", [-2487.65, 514.33, -3021.60], KorokType.IceBlock, "");
		this.addKorok("H30", [-2250.21, 446.80, -3092.18], KorokType.AcornFlying, "");
		this.addKorok("H31", [-2210.05, 498.64, -2908.54], KorokType.IceBlock, "");
		this.addKorok("H32", [-4402.55, 415.03, -2866.69], KorokType.LightChase, "On flat hill");
		this.addKorok("H33", [-3115.96, 496.35, -2806.23], KorokType.IceBlock, "");
		this.addKorok("H34", [-3061.22, 535.21, -2813.68], KorokType.IceBlock, "");
		this.addKorok("H35", [-2830.55, 669.61, -2828.34], KorokType.LiftRock, "");
		this.addKorok("H36", [-2467.77, 620.80, -2765.22], KorokType.RockCircle, "");
		this.addKorok("H37", [-1781.00, 347.36, -2745.01], KorokType.LightChase, "");
		this.addKorok("H38", [-1349.37, 241.70, -2734.51], KorokType.LiftRockRubble, "");
		this.addKorok("H39", [-4192.80, 288.37, -2678.94], KorokType.FlowerChase, "");
		this.addKorok("H40", [-4330.86, 441.88, -2600.16], KorokType.LiftRock, "Top of hill");
		this.addKorok("H41", [-4148.75, 216.39, -2616.47], KorokType.RockCircle, "");
		this.addKorok("H42", [-4071.81, 131.41, -2545.94], KorokType.LiftRockRubble, "");
		this.addKorok("H43", [-3277.42, 585.32, -2618.86], KorokType.AcornLog, "");
		this.addKorok("H44", [-2984.89, 559.20, -2693.52], KorokType.Balloon, "In cave");
		this.addKorok("H45", [-2736.11, 456.88, -2582.83], KorokType.IceBlock, "");
		this.addKorok("H46", [-3041.11, 430.43, -2520.18], KorokType.IceBlock, "");
		this.addKorok("H47", [-2900.08, 498.64, -2508.78], KorokType.Balloon, "End of river");
		this.addKorok("H48", [-2936.53, 471.20, -2412.46], KorokType.LiftRock, "On ledge");
		this.addKorok("H49", [-2883.83, 521.33, -2398.01], KorokType.Race, "");
		this.addKorok("H50", [-2836.29, 501.87, -2423.83], KorokType.Balloon, "Middle of river");
		this.addKorok("H51", [-2638.89, 410.03, -2364.10], KorokType.LiftRock, "Ledge down the camp");
		this.addKorok("H52", [-2015.55, 347.55, -2456.00], KorokType.AcornLog, "");
		this.addKorok("H53", [-4340.22, 301.85, -2354.10], KorokType.LiftRock, "Sharp hill");
		this.addKorok("H54", [-3677.89, 426.24, -2466.75], KorokType.LiftRock, "");
		this.addKorok("H55", [-3331.94, 508.74, -2297.16], KorokType.Balloon, "");
		this.addKorok("H56", [-2751.33, 459.46, -2258.77], KorokType.FlowerChase, "Across bridge");
		this.addKorok("H57", [-2297.36, 424.84, -2293.14], KorokType.SnowballGolf, "");
		this.addKorok("H58", [-1764.0, 343.69, -2350.70], KorokType.LiftRockLeaves, "");
		this.addKorok("H59", [-1414.64, 228.58, -2398.59], KorokType.BoulderGolf, "");
		this.addKorok("H60", [-4507.23, 226.34, -2146.10], KorokType.Balloon, "");
		this.addKorok("H61", [-2382.54, 498.73, -2132.67], KorokType.LiftRock, "Peak");
		this.addKorok("H62", [-1682.21, 353.47, -2184.83], KorokType.Balloon, "");
		this.addKorok("H63", [-1675.99, 96.53, -2139.30], KorokType.LiftRock, "Ledge");
		this.addKorok("H64", [-2636.62, 444.70, -2048.35], KorokType.LiftRock, "Behind shrine");
		this.addKorok("H65", [-2791.12, 397.69, -1945.59], KorokType.Confetti, "Top of tree (island)");
		this.addKorok("H66", [-2972.81, 377.29, -1878.10], KorokType.LilyPads, "");
		this.addKorok("H67", [-2358.59, 385.00, -1872.80], KorokType.Well, "");
		this.addKorok("H68", [-2012.59, 127.18, -1921.69], KorokType.LiftRock, "");
		this.addKorok("H69", [-2523.39, 364.82, -1736.94], KorokType.AcornLog, "");
		this.addKorok("H70", [-2338.22, 369.24, -1726.70], KorokType.FlowerChase, "");
		this.addKorok("H71", [-2207.48, 253.30, -1767.46], KorokType.LiftRockRubble, "");
		this.addKorok("H72", [-2836.95, 348.83, -1594.78], KorokType.Confetti, "Top of windmill");
		this.addKorok("H73", [-2826.25, 193.14, -1580.07], KorokType.Race, "");
		this.addKorok("K01", [206.76, 233.04, -3559.00], KorokType.LiftRock, "");
		this.addKorok("K02", [603.39, 249.91, -3633.77], KorokType.BlockPuzzle, "");
		this.addKorok("K03", [1143.40, 209.96, -3742.77], KorokType.RockCircle, "");
		this.addKorok("K04", [863.91, 316.28, -3358.52], KorokType.Balloon, "");
		this.addKorok("K05", [-486.71, 231.99, -3142.91], KorokType.Balloon, "");
		this.addKorok("K06", [-40.78, 306.50, -2985.74], KorokType.AcornLog, "");
		this.addKorok("K07", [237.43, 304.06, -2943.38], KorokType.Balloon, "");
		this.addKorok("K08", [685.12, 431.88, -2758.45], KorokType.Balloon, "");
		this.addKorok("K09", [-1045.91, 54.66, -2707.63], KorokType.LiftRock, "Behind statue");
		this.addKorok("K10", [-1047.62, 139.68, -2620.56], KorokType.BlockPuzzle, "");
		this.addKorok("K11", [-567.78, 489.60, -2599.20], KorokType.LiftRock, "");
		this.addKorok("K12", [-1258.63, 138.61, -2360.09], KorokType.LiftRockRubble, "");
		this.addKorok("K13", [-1403.11, 101.02, -2312.28], KorokType.LiftRockRubble, "");
		this.addKorok("K14", [-1410.55, 1.01, -2224.80], KorokType.LiftRockDoor, "");
		this.addKorok("K15", [-1586.48, 68.14, -2133.00], KorokType.LiftRock, "On pillar");
		this.addKorok("K16", [-1019.51, 222.85, -2077.29], KorokType.AcornLog, "");
		this.addKorok("K17", [-1309.76, 209.07, -1941.56], KorokType.JumpFence, "");
		this.addKorok("K18", [-1062.99, 226.91, -1934.10], KorokType.Confetti, "Top of broken hut");
		this.addKorok("K19", [-816.67, 224.46, -1926.15], KorokType.AcornLog, "");
		this.addKorok("K20", [-1884.49, 23.98, -1813.18], KorokType.LiftRock, "End of ledge");
		this.addKorok("K21", [-1805.35, 31.56, -1842.52], KorokType.LiftRockRubble, "");
		this.addKorok("K22", [-2036.94, 48.70, -1622.94], KorokType.LiftRockRubble, "In corner");
		this.addKorok("K23", [-1436.28, 231.00, -1668.66], KorokType.AcornLog, "");
		this.addKorok("K24", [-1227.23, 255.95, -1578.17], KorokType.LightChase, "");
		this.addKorok("K25", [-1094.13, 210.87, -1731.89], KorokType.RockCircle, "");
		this.addKorok("K26", [-911.80, 219.25, -1612.79], KorokType.Balloon, "Pinwheel");
		this.addKorok("K27", [-427.40, 317.62, -1734.04], KorokType.Balloon, "");
		this.addKorok("K28", [-332.59, 299.39, -1747.38], KorokType.LiftRock, "");
		this.addKorok("K29", [-703.68, 247.65, -1443.78], KorokType.AcornLog, "");
		this.addKorok("K30", [-130.54, 168.77, -1379.27], KorokType.LiftRock, "Ledge");
		this.addKorok("K31", [1008.13, 270.26, -1564.01], KorokType.LiftRock, "Top of scaffolding");
		this.addKorok("K32", [868.01, 209.81, -1362.16], KorokType.AcornTree, "");
		this.addKorok("K33", [1197.52, 126.97, -1152.62], KorokType.BlockPuzzle, "");
		this.addKorok("K34", [1282.64, 129.64, -1123.44], KorokType.LilyPads, "");
		this.addKorok("K35", [1694.87, 141.95, -862.08], KorokType.BlockPuzzle, "");
		this.addKorok("L01", [323.49, 118.23, 1927.42], KorokType.Well, "");
		this.addKorok("L02", [354.38, 114.56, 1997.54], KorokType.LiftRock, "In big tree stump");
		this.addKorok("L03", [34.27, 115.55, 2102.72], KorokType.BlockPuzzle, "");
		this.addKorok("L04", [214.57, 170.07, 2070.05], KorokType.TreeStump, "");
		this.addKorok("L05", [400.86, 142.06, 2150.86], KorokType.FlowerChase, "");
		this.addKorok("L06", [474.95, 115.26, 2149.12], KorokType.LightChase, "");
		this.addKorok("L07", [908.28, 247.17, 2193.52], KorokType.BoulderGolf, "");
		this.addKorok("L08", [-609.80, 95.24, 2390.65], KorokType.Race, "");
		this.addKorok("L09", [-68.02, 91.39, 2332.61], KorokType.Basketball, "");
		this.addKorok("L10", [66.22, 94.00, 2310.64], KorokType.LilyPads, "");
		this.addKorok("L11", [284.29, 146.79, 2253.37], KorokType.TreeStump, "");
		this.addKorok("L12", [625.16, 164.76, 2305.90], KorokType.BlockPuzzle, "");
		this.addKorok("L13", [755.69, 194.09, 2347.60], KorokType.LiftRockRubble, "");
		this.addKorok("L14", [-74.38, 90.54, 2412.87], KorokType.LiftRockSlab, "");
		this.addKorok("L15", [-66.24, 124.00, 2440.65], KorokType.Balloon, "In pillar");
		this.addKorok("L16", [280.42, 97.65, 2405.01], KorokType.BoulderGolf, "");
		this.addKorok("L17", [604.93, 177.15, 2408.78], KorokType.LightChase, "");
		this.addKorok("L18", [609.28, 177.23, 2409.82], KorokType.LightChase, "");
		this.addKorok("L19", [889.61, 180.86, 2395.30], KorokType.LiftRockTree, "Top of big tree");
		this.addKorok("L20", [833.75, 145.33, 2434.57], KorokType.BlockPuzzle, "");
		this.addKorok("L21", [-1379.58, 215.09, 2742.73], KorokType.BlockPuzzle, "");
		this.addKorok("L22", [-1214.31, 193.47, 2701.55], KorokType.AcornHanging, "From tree");
		this.addKorok("L23", [-1011.91, 330.57, 2638.46], KorokType.Confetti, "Top of tree");
		this.addKorok("L24", [-601.95, 90.30, 2656.11], KorokType.LiftRock, "");
		this.addKorok("L25", [-398.39, 103.86, 2559.23], KorokType.Confetti, "Top of tree");
		this.addKorok("L26", [-54.77, 128.94, 2492.43], KorokType.TreeBranch, "");
		this.addKorok("L27", [-38.98, 152.95, 2513.64], KorokType.Confetti, "Top of fountain");
		this.addKorok("L28", [515.78, 211.07, 2499.00], KorokType.FlowerCount, "On peak");
		this.addKorok("L29", [679.19, 244.79, 2499.42], KorokType.LiftRock, "Peak");
		this.addKorok("L30", [-12.47, 119.77, 2586.32], KorokType.FlowerChase, "");
		this.addKorok("L31", [-3.03, 90.67, 2614.66], KorokType.LiftRock, "");
		this.addKorok("L32", [200.87, 92.34, 2603.86], KorokType.MetalBoxCircle, "");
		this.addKorok("L33", [370.41, 92.51, 2567.04], KorokType.Balloon, "");
		this.addKorok("L34", [790.63, 159.60, 2565.13], KorokType.BoulderCircle, "");
		this.addKorok("L35", [584.43, 217.51, 2678.33], KorokType.MatchTree, "");
		this.addKorok("L36", [-1066.79, 208.43, 2910.01], KorokType.Balloon, "");
		this.addKorok("L37", [-925.24, 237.01, 2936.44], KorokType.AcornLog, "");
		this.addKorok("L38", [-468.64, 98.70, 2908.23], KorokType.BlockPuzzle, "");
		this.addKorok("L39", [163.27, 90.20, 2783.29], KorokType.LiftRock, "");
		this.addKorok("L40", [340.62, 94.23, 2830.62], KorokType.AcornTree, "");
		this.addKorok("L41", [-1256.74, 323.02, 3134.15], KorokType.LiftRockRubble, "");
		this.addKorok("L42", [224.17, 135.20, 2868.40], KorokType.BoulderGolf, "");
		this.addKorok("L43", [169.73, 118.72, 2903.41], KorokType.LightChase, "");
		this.addKorok("L44", [418.20, 128.01, 2873.40], KorokType.BoulderGolf, "");
		this.addKorok("L45", [610.34, 206.40, 2869.85], KorokType.LiftRock, "");
		this.addKorok("L46", [1173.39, 192.66, 2849.98], KorokType.LiftRock, "Top of small waterfall");
		this.addKorok("L47", [-387.59, 152.98, 3151.55], KorokType.Race, "");
		this.addKorok("L48", [-150.92, 143.38, 3067.30], KorokType.LiftRock, "");
		this.addKorok("L49", [-95.25, 144.64, 3128.95], KorokType.RockCircle, "");
		this.addKorok("L50", [-26.89, 107.81, 3042.68], KorokType.BlockPuzzle, "");
		this.addKorok("L51", [153.94, 143.86, 2981.71], KorokType.BoulderGolf, "");
		this.addKorok("L52", [406.11, 139.90, 3051.13], KorokType.LiftRock, "On pillar");
		this.addKorok("L53", [581.24, 123.03, 3007.60], KorokType.TreeStump, "");
		this.addKorok("L54", [704.03, 120.0, 2957.83], KorokType.LilyPads, "");
		this.addKorok("L55", [695.52, 119.43, 3071.40], KorokType.FlowerChase, "");
		this.addKorok("L56", [824.94, 120.73, 3005.30], KorokType.BoulderCircle, "");
		this.addKorok("L57", [806.07, 124.54, 3112.85], KorokType.LightChase, "");
		this.addKorok("L58", [1196.09, 132.03, 3055.43], KorokType.LightChase, "");
		this.addKorok("L59", [56.69, 109.89, 3150.02], KorokType.LilyPads, "");
		this.addKorok("L60", [69.03, 127.14, 3221.87], KorokType.LiftRockRubble, "");
		this.addKorok("L61", [742.89, 134.01, 3243.07], KorokType.LiftRockSlab, "");
		this.addKorok("L62", [-923.50, 214.58, 3436.38], KorokType.LiftRockRubble, "");
		this.addKorok("L63", [-420.75, 141.37, 3368.48], KorokType.OfferApple, "");
		this.addKorok("L64", [-227.28, 176.72, 3342.73], KorokType.FlowerChase, "");
		this.addKorok("L65", [271.71, 222.37, 3289.58], KorokType.Race, "Ordinal");
		this.addKorok("L66", [699.92, 133.67, 3276.86], KorokType.Basketball, "");
		this.addKorok("L67", [-1176.85, 344.99, 3578.05], KorokType.Balloon, "");
		this.addKorok("L68", [-1125.95, 315.84, 3624.42], KorokType.LightChase, "");
		this.addKorok("L69", [-878.72, 245.87, 3567.74], KorokType.BlockPuzzle, "");
		this.addKorok("L70", [216.32, 199.14, 3488.01], KorokType.AcornFlying, "");
		this.addKorok("L71", [279.02, 184.63, 3503.79], KorokType.RockCircle, "");
		this.addKorok("L72", [963.30, 173.48, 3371.10], KorokType.Race, "");
		this.addKorok("L73", [266.10, 276.46, 3668.95], KorokType.Balloon, "On peak");
		this.addKorok("L74", [367.09, 176.93, 3642.11], KorokType.BoulderGolf, "");
		this.addKorok("L75", [645.72, 152.30, 3615.12], KorokType.LilyPads, "");
		this.addKorok("L76", [522.46, 166.30, 3680.95], KorokType.BoulderGolf, "");
		this.addKorok("L77", [639.39, 219.09, 3710.83], KorokType.RockCircle, "");
		this.addKorok("L78", [891.16, 284.36, 3694.82], KorokType.LiftRock, "Peak");
		this.addKorok("L79", [1074.54, 182.09, 3620.94], KorokType.RockCircle, "");
		this.addKorok("L80", [-657.76, 187.62, 3851.83], KorokType.Race, "");
		this.addKorok("L81", [-354.83, 182.59, 3898.78], KorokType.LiftRock, "");
		this.addKorok("L82", [-266.35, 125.15, 3888.15], KorokType.Confetti, "Top of palm tree");
		this.addKorok("L83", [-248.98, 130.31, 3828.44], KorokType.LiftRockRubble, "");
		this.addKorok("L84", [-240.11, 106.07, 3882.50], KorokType.LightChase, "");
		this.addKorok("L85", [-135.83, 105.97, 3802.00], KorokType.RockCircle, "");
		this.addKorok("L86", [13.95, 106.59, 3790.42], KorokType.LiftRockBoulder, "");
		this.addKorok("L87", [97.36, 145.46, 3821.54], KorokType.LiftRock, "Top of rock");
		this.addKorok("L88", [265.62, 111.81, 3837.00], KorokType.LightChase, "");
		this.addKorok("L89", [286.83, 109.90, 3899.42], KorokType.LilyPads, "");
		this.addKorok("L90", [416.75, 106.41, 3848.92], KorokType.BlockPuzzle, "");
		this.addKorok("L91", [517.56, 193.85, 3765.02], KorokType.RockCircle, "");
		this.addKorok("L92", [909.62, 183.59, 3837.29], KorokType.BlockPuzzle, "");
		this.addKorok("N01", [1980.92, 174.27, 451.91], KorokType.LiftRockTree, "");
		this.addKorok("N02", [2378.29, 231.61, 373.14], KorokType.LiftRockTree, "");
		this.addKorok("N03", [2635.69, 269.52, 370.99], KorokType.LiftRockTree, "");
		this.addKorok("N04", [2473.88, 231.46, 461.69], KorokType.AcornFlying, "");
		this.addKorok("N05", [2684.85, 218.51, 562.78], KorokType.LightChase, "Small pond");
		this.addKorok("N06", [2891.03, 234.15, 517.87], KorokType.Confetti, "Top of tall tree");
		this.addKorok("N07", [2132.42, 321.11, 678.84], KorokType.MatchTree, "");
		this.addKorok("N08", [2149.35, 262.51, 790.00], KorokType.BlockPuzzle, "");
		this.addKorok("N09", [2239.66, 337.97, 726.35], KorokType.OfferApple, "");
		this.addKorok("N10", [2288.52, 250.79, 699.62], KorokType.FlowerChase, "");
		this.addKorok("N11", [2409.54, 269.60, 849.31], KorokType.BoulderGolf, "");
		this.addKorok("N12", [3951.86, 106.31, 695.88], KorokType.RockCircle, "");
		this.addKorok("N13", [2728.91, 342.09, 904.32], KorokType.BoulderCircle, "");
		this.addKorok("N14", [3383.03, 118.78, 982.82], KorokType.RockCircle, "");
		this.addKorok("N15", [2636.16, 205.28, 1108.99], KorokType.LilyPads, "");
		this.addKorok("N16", [2705.43, 203.10, 1164.94], KorokType.LiftRock, "");
		this.addKorok("N17", [3432.94, 342.95, 1150.31], KorokType.Race, "");
		this.addKorok("N18", [3741.71, 542.51, 1156.12], KorokType.IceBlock, "");
		this.addKorok("N19", [4087.93, 423.30, 1142.37], KorokType.RockCircle, "");
		this.addKorok("N20", [2526.61, 334.82, 1257.57], KorokType.RockCircle, "");
		this.addKorok("N21", [2925.46, 478.75, 1401.57], KorokType.LiftRock, "");
		this.addKorok("N22", [3277.77, 346.34, 1436.00], KorokType.BlockPuzzle, "");
		this.addKorok("N23", [3537.01, 313.54, 1376.11], KorokType.LightChase, "In forest");
		this.addKorok("N24", [3797.93, 577.85, 1269.19], KorokType.IceBlock, "");
		this.addKorok("N25", [4438.90, 141.72, 1432.91], KorokType.BoulderGolf, "");
		this.addKorok("N26", [2276.03, 162.25, 1501.62], KorokType.BoulderGolf, "");
		this.addKorok("N27", [2279.31, 182.42, 1608.34], KorokType.LiftRockRubble, "");
		this.addKorok("N28", [2511.43, 120.03, 1571.52], KorokType.OfferApple, "");
		this.addKorok("N29", [3171.94, 293.84, 1490.93], KorokType.LilyPads, "");
		this.addKorok("N30", [3229.03, 302.83, 1545.24], KorokType.MatchTree, "");
		this.addKorok("N31", [3896.64, 416.66, 1556.54], KorokType.IceBlock, "");
		this.addKorok("N32", [4282.68, 289.03, 1655.66], KorokType.Race, "");
		this.addKorok("N33", [2320.66, 119.27, 1726.36], KorokType.LiftRockDoor, "");
		this.addKorok("N34", [2330.77, 133.96, 1828.01], KorokType.AcornFlying, "");
		this.addKorok("N35", [2495.85, 205.50, 1804.20], KorokType.BlockPuzzle, "");
		this.addKorok("N36", [2884.09, 160.92, 1765.02], KorokType.AcornTree, "");
		this.addKorok("N37", [3178.81, 177.00, 1607.30], KorokType.LilyPads, "");
		this.addKorok("N38", [3166.32, 193.83, 1828.89], KorokType.Balloon, "");
		this.addKorok("N39", [3525.98, 454.04, 1710.62], KorokType.Race, "");
		this.addKorok("N40", [4615.14, 106.21, 1915.47], KorokType.LiftRockSlab, "");
		this.addKorok("N41", [3029.03, 114.80, 2017.67], KorokType.LilyPads, "");
		this.addKorok("N42", [3475.63, 278.69, 1916.26], KorokType.LiftRockTree, "Next to blue flame");
		this.addKorok("N43", [3709.19, 301.45, 1943.08], KorokType.LiftRockTree, "Middle of lake");
		this.addKorok("N44", [4274.36, 430.79, 1981.02], KorokType.FlowerChase, "");
		this.addKorok("N45", [2836.32, 126.63, 2120.50], KorokType.Balloon, "");
		this.addKorok("N46", [2863.86, 140.26, 2182.90], KorokType.LiftRockDoor, "");
		this.addKorok("N47", [3783.72, 390.72, 2125.18], KorokType.Confetti, "Top of lab");
		this.addKorok("N48", [3901.08, 235.80, 2096.72], KorokType.LilyPads, "");
		this.addKorok("N49", [2714.13, 225.53, 2345.55], KorokType.AcornTree, "");
		this.addKorok("N50", [2811.31, 239.61, 2391.92], KorokType.MatchTree, "");
		this.addKorok("N51", [2991.39, 118.10, 2272.14], KorokType.LiftRock, "Side of lake");
		this.addKorok("N52", [3144.46, 163.40, 2229.85], KorokType.AcornTree, "");
		this.addKorok("N53", [3347.61, 209.94, 2249.25], KorokType.LiftRock, "");
		this.addKorok("N54", [4211.83, 106.38, 2270.37], KorokType.OfferPalmFruit, "In cave");
		this.addKorok("N55", [4518.49, 219.77, 2316.51], KorokType.Balloon, "");
		this.addKorok("N56", [4541.93, 109.80, 2405.63], KorokType.LilyPads, "");
		this.addKorok("N57", [4662.71, 169.59, 2376.25], KorokType.FlowerChase, "");
		this.addKorok("N58", [3148.71, 277.19, 2402.78], KorokType.BlockPuzzle, "");
		this.addKorok("N59", [3420.94, 340.35, 2390.28], KorokType.FlowerChase, "");
		this.addKorok("N60", [3687.95, 107.32, 2413.49], KorokType.Basketball, "");
		this.addKorok("N61", [3516.11, 160.20, 2628.38], KorokType.RockCircle, "");
		this.addKorok("N62", [4110.97, 111.15, 2588.16], KorokType.FlowerChase, "Starts on beach");
		this.addKorok("N63", [4536.52, 106.44, 2520.27], KorokType.RockCircle, "");
		this.addKorok("N64", [3229.81, 256.54, 2848.44], KorokType.MatchTree, "");
		this.addKorok("N65", [3441.93, 216.08, 3121.87], KorokType.RockCircle, "");
		this.addKorok("N66", [4083.59, 109.36, 2973.26], KorokType.Race, "");
		this.addKorok("P01", [-822.63, 171.23, 1546.92], KorokType.LiftRock, "");
		this.addKorok("P02", [-769.88, 175.22, 1574.22], KorokType.TreeStump, "");
		this.addKorok("P03", [-1334.80, 232.67, 1675.89], KorokType.BlockPuzzle, "");
		this.addKorok("P04", [-965.48, 187.20, 1625.69], KorokType.FlowerChase, "Middle of log");
		this.addKorok("P05", [-853.23, 181.42, 1671.21], KorokType.LiftRockLeaves, "");
		this.addKorok("P06", [-953.06, 201.55, 1721.48], KorokType.LiftRock, "Top of hill");
		this.addKorok("P07", [-1503.42, 297.22, 1920.17], KorokType.IceBlock, "");
		this.addKorok("P08", [-1382.26, 249.27, 1858.03], KorokType.Confetti, "");
		this.addKorok("P09", [-1132.60, 238.22, 1917.72], KorokType.Confetti, "Inside SoR");
		this.addKorok("P10", [-887.32, 208.14, 1891.40], KorokType.LilyPads, "");
		this.addKorok("P11", [-809.22, 297.75, 1966.90], KorokType.Confetti, "Spire of Temple");
		this.addKorok("P12", [-423.02, 169.40, 1993.36], KorokType.LiftRockDoor, "");
		this.addKorok("P13", [-423.35, 125.26, 2022.45], KorokType.LiftRockDoor, "");
		this.addKorok("P14", [-1443.11, 284.25, 2105.63], KorokType.BlockPuzzle, "");
		this.addKorok("P15", [-1363.46, 335.70, 2215.19], KorokType.RockCircle, "");
		this.addKorok("P16", [-1204.35, 329.34, 2322.77], KorokType.LiftRockSlab, "");
		this.addKorok("P17", [-1171.61, 342.50, 2317.22], KorokType.LiftRock, "");
		this.addKorok("P18", [-792.03, 201.39, 2257.22], KorokType.Confetti, "Top of hut");
		this.addKorok("R01", [-2210.59, 152.82, -1621.40], KorokType.BlockPuzzle, "");
		this.addKorok("R02", [-2217.82, 321.18, -1565.02], KorokType.LiftRock, "");
		this.addKorok("R03", [-2083.96, 293.08, -1558.72], KorokType.LiftRockRubble, "");
		this.addKorok("R04", [-2516.12, 61.96, -1436.30], KorokType.RockCircle, "In canyon");
		this.addKorok("R05", [-2636.28, 300.54, -1246.99], KorokType.AcornTree, "");
		this.addKorok("R06", [-2022.65, 390.93, -1300.63], KorokType.Confetti, "Top of bare tree");
		this.addKorok("R07", [-1077.38, 238.53, -1278.70], KorokType.LiftRockTree, "");
		this.addKorok("R08", [-2383.52, 211.84, -1150.63], KorokType.LiftRockRubble, "");
		this.addKorok("R09", [-1855.98, 395.49, -1192.54], KorokType.Race, "Run");
		this.addKorok("R10", [-1230.51, 207.67, -999.81], KorokType.FlowerChase, "");
		this.addKorok("R11", [-1017.85, 116.95, -1066.07], KorokType.Basketball, "");
		this.addKorok("R12", [-3109.25, 34.17, -909.26], KorokType.Confetti, "Top of flagpole");
		this.addKorok("R13", [-2669.55, 375.56, -864.39], KorokType.LiftRock, "Top of waterfall");
		this.addKorok("R14", [-2316.80, 206.68, -806.45], KorokType.LightChase, "");
		this.addKorok("R15", [-2060.66, 259.49, -775.78], KorokType.RockCircle, "On top of tree");
		this.addKorok("R16", [-1142.41, 182.62, -761.94], KorokType.BlockPuzzle, "");
		this.addKorok("R17", [-2698.41, 414.59, -766.74], KorokType.LiftRock, "Top of mountain");
		this.addKorok("R18", [-1964.67, 221.20, -584.16], KorokType.Race, "Run");
		this.addKorok("R19", [-1125.27, 157.91, -675.43], KorokType.BoulderGolf, "");
		this.addKorok("R20", [-3207.78, 37.56, -494.89], KorokType.BlockPuzzle, "");
		this.addKorok("R21", [-2715.90, 269.56, -429.86], KorokType.LightChase, "");
		this.addKorok("R22", [-2434.11, 319.86, -491.03], KorokType.Balloon, "");
		this.addKorok("R23", [-2030.12, 206.47, -547.60], KorokType.FlowerChase, "");
		this.addKorok("R24", [-2008.68, 210.31, -514.15], KorokType.LilyPads, "");
		this.addKorok("R25", [-1631.54, 210.43, -515.19], KorokType.Race, "");
		this.addKorok("R26", [-1115.55, 151.98, -528.58], KorokType.Race, "");
		this.addKorok("R27", [-1092.08, 129.02, -497.51], KorokType.AcornHanging, "From bridge");
		this.addKorok("R28", [-3327.44, 3.92, -336.92], KorokType.LiftRockSlab, "");
		this.addKorok("R29", [-3207.55, 5.91, -333.98], KorokType.LiftRock, "In between rocks");
		this.addKorok("R30", [-2547.83, 299.94, -306.30], KorokType.Race, "");
		this.addKorok("R31", [-2370.10, 307.04, -345.30], KorokType.Balloon, "");
		this.addKorok("R32", [-4252.40, 171.93, -84.01], KorokType.Balloon, "");
		this.addKorok("R33", [-4026.70, 145.21, -232.40], KorokType.RockCircle, "");
		this.addKorok("R34", [-3861.73, 101.01, -147.15], KorokType.AcornHanging, "Below tower");
		this.addKorok("R35", [-3828.83, 160.15, -118.42], KorokType.Race, "");
		this.addKorok("R36", [-3476.20, 154.93, -69.05], KorokType.LiftRock, "On ledge");
		this.addKorok("R37", [-2312.60, 262.63, 24.01], KorokType.LiftRock, "Ledge");
		this.addKorok("R38", [-1877.90, 243.70, -32.18], KorokType.MatchTree, "");
		this.addKorok("R39", [-1691.98, 224.96, -175.52], KorokType.LightChase, "Near 2 rocks");
		this.addKorok("R40", [-1590.03, 210.73, -229.13], KorokType.LiftRockSlab, "");
		this.addKorok("R41", [-1650.75, 224.85, -46.55], KorokType.FlowerCount, "");
		this.addKorok("R42", [-1372.53, 115.84, -107.98], KorokType.LiftRock, "Riverbank");
		this.addKorok("R43", [-1258.40, 159.75, -240.51], KorokType.Balloon, "");
		this.addKorok("R44", [-1142.67, 116.47, -284.33], KorokType.Basketball, "");
		this.addKorok("R45", [-1148.19, 149.37, -226.68], KorokType.FlowerChase, "Start at pillar");
		this.addKorok("R46", [-3464.51, 172.14, 172.49], KorokType.FlowerCount, "");
		this.addKorok("R47", [-1992.89, 291.31, 161.31], KorokType.Balloon, "");
		this.addKorok("R48", [-1512.01, 177.03, 56.45], KorokType.AcornTree, "");
		this.addKorok("R49", [-1501.96, 118.02, 156.21], KorokType.BlockPuzzle, "");
		this.addKorok("R50", [-3014.16, 174.95, 372.43], KorokType.Balloon, "In tree");
		this.addKorok("R51", [-2450.16, 317.93, 373.47], KorokType.RockCircle, "");
		this.addKorok("R52", [-2298.85, 462.71, 341.16], KorokType.LiftRockTree, "");
		this.addKorok("R53", [-2041.04, 425.55, 296.35], KorokType.LiftRockRubble, "");
		this.addKorok("R54", [-1984.86, 348.49, 362.94], KorokType.Confetti, "Top of tree");
		this.addKorok("R55", [-1962.84, 314.61, 409.85], KorokType.LiftRock, "In tree stump");
		this.addKorok("R56", [-1859.58, 272.62, 334.30], KorokType.AcornTree, "");
		this.addKorok("R57", [-1731.07, 216.96, 375.13], KorokType.AcornTree, "");
		this.addKorok("R58", [-1405.51, 162.60, 459.70], KorokType.LiftRockTree, "");
		this.addKorok("R59", [-2918.60, 118.24, 479.73], KorokType.LiftRock, "Small island");
		this.addKorok("R60", [-2290.55, 438.38, 468.23], KorokType.LiftRockLeaves, "");
		this.addKorok("R61", [-2247.71, 446.01, 464.67], KorokType.FlowerChase, "");
		this.addKorok("R62", [-2175.12, 407.09, 463.35], KorokType.Race, "Cardinal");
		this.addKorok("R63", [-2645.97, 300.16, 547.92], KorokType.LiftRockLeaves, "");
		this.addKorok("R64", [-2483.31, 328.14, 504.73], KorokType.LiftRockRubble, "");
		this.addKorok("R65", [-2483.61, 307.72, 545.07], KorokType.FlowerCount, "");
		this.addKorok("R66", [-2492.70, 310.45, 589.67], KorokType.TreeStump, "");
		this.addKorok("R67", [-2542.83, 307.78, 639.85], KorokType.Basketball, "");
		this.addKorok("R68", [-1965.81, 255.74, 600.94], KorokType.Balloon, "");
		this.addKorok("R69", [-2053.48, 258.46, 636.41], KorokType.AcornLog, "");
		this.addKorok("R70", [-2200.96, 358.41, 730.84], KorokType.LiftRockBoulder, "");
		this.addKorok("R71", [-2908.73, 116.66, 796.26], KorokType.LiftRock, "");
		this.addKorok("R72", [-2729.92, 219.93, 761.38], KorokType.LiftRockLeaves, "");
		this.addKorok("R73", [-2351.14, 263.40, 846.17], KorokType.BoulderGolf, "");
		this.addKorok("R74", [-1551.96, 167.38, 815.48], KorokType.LiftRock, "");
		this.addKorok("R75", [-2393.36, 233.04, 1047.34], KorokType.AcornHanging, "Bomb from midair");
		this.addKorok("R76", [-2340.70, 249.86, 1104.57], KorokType.LiftRockTree, "");
		this.addKorok("R77", [-2099.38, 205.46, 1104.72], KorokType.AcornHanging, "From tree");
		this.addKorok("R78", [-1969.74, 205.39, 1087.83], KorokType.OfferApple, "");
		this.addKorok("R79", [-1819.30, 248.49, 973.59], KorokType.OfferApple, "");
		this.addKorok("R80", [-1819.09, 206.17, 1532.64], KorokType.LiftRock, "");
		this.addKorok("T01", [-4019.88, 130.80, -2350.35], KorokType.FlowerChase, "");
		this.addKorok("T02", [-3792.61, 283.47, -2320.19], KorokType.Confetti, "Top of house");
		this.addKorok("T03", [-2989.56, 222.60, -2164.98], KorokType.LilyPads, "");
		this.addKorok("T04", [-3831.34, 248.41, -2106.70], KorokType.Well, "");
		this.addKorok("T05", [-3418.51, 286.38, -2066.81], KorokType.LiftRock, "");
		this.addKorok("T06", [-4396.83, 183.40, -2049.88], KorokType.Race, "");
		this.addKorok("T07", [-4330.52, 160.93, -2052.85], KorokType.RockCircle, "");
		this.addKorok("T08", [-4482.92, 266.76, -1970.87], KorokType.LiftRock, "");
		this.addKorok("T09", [-4123.61, 303.52, -1935.33], KorokType.OfferPepper, "");
		this.addKorok("T10", [-3780.53, 368.60, -1865.62], KorokType.OfferApple, "");
		this.addKorok("T11", [-3271.39, 244.43, -1868.25], KorokType.LiftRockTree, "Big tree");
		this.addKorok("T12", [-4225.71, 216.79, -1747.38], KorokType.LiftRockLeaves, "");
		this.addKorok("T13", [-4034.98, 273.22, -1748.40], KorokType.RockCircle, "");
		this.addKorok("T14", [-3627.38, 453.55, -1804.82], KorokType.Balloon, "");
		this.addKorok("T15", [-3562.24, 289.50, -1761.34], KorokType.LiftRock, "");
		this.addKorok("T16", [-4065.46, 316.05, -1678.32], KorokType.FlowerChase, "");
		this.addKorok("T17", [-3696.66, 211.10, -1684.10], KorokType.Race, "");
		this.addKorok("T18", [-2910.18, 382.29, -1639.03], KorokType.Confetti, "Top of tree");
		this.addKorok("T19", [-3151.57, 185.5, -1563.28], KorokType.LilyPads, "");
		this.addKorok("T20", [-3444.36, 231.21, -1328.68], KorokType.BlockPuzzle, "");
		this.addKorok("T21", [-3295.15, 250.09, -1362.92], KorokType.LiftRockRubble, "");
		this.addKorok("T22", [-3125.19, 263.00, -1366.50], KorokType.LiftRockRubble, "");
		this.addKorok("T23", [-4011.17, 205.67, -1167.08], KorokType.RockCircle, "");
		this.addKorok("T24", [-3714.73, 302.72, -1040.96], KorokType.LiftRock, "Behind malice");
		this.addKorok("T25", [-3078.34, 207.99, -1120.35], KorokType.LiftRock, "On ledge");
		this.addKorok("T26", [-3971.63, 213.63, -920.51], KorokType.TreeStump, "");
		this.addKorok("T27", [-4058.83, 208.52, -773.85], KorokType.OfferApple, "");
		this.addKorok("T28", [-3841.71, 218.41, -803.98], KorokType.BoulderGolf, "");
		this.addKorok("T29", [-3803.93, 251.28, -772.46], KorokType.LiftRock, "");
		this.addKorok("T30", [-3661.36, 257.37, -828.80], KorokType.Race, "");
		this.addKorok("T31", [-3431.20, 278.01, -707.84], KorokType.OfferApple, "");
		this.addKorok("T32", [-3966.31, 293.92, -624.56], KorokType.LiftRock, "On pillar");
		this.addKorok("T33", [-3441.07, 357.36, -664.87], KorokType.Balloon, "");
		this.addKorok("T34", [-3212.85, 235.00, -574.01], KorokType.Race, "");
		this.addKorok("T35", [-3524.55, 373.78, -449.69], KorokType.LilyPads, "");
		this.addKorok("T36", [-4032.73, 239.31, -350.42], KorokType.FlowerCount, "");
		this.addKorok("T37", [-3675.03, 390.49, -393.32], KorokType.Race, "");
		this.addKorok("W01", [-3847.19, 471.28, 1467.01], KorokType.LiftRock, "");
		this.addKorok("W02", [-4001.07, 324.38, 1566.55], KorokType.OfferBanana, "");
		this.addKorok("W03", [-4314.10, 219.01, 1629.40], KorokType.OfferBanana, "");
		this.addKorok("W04", [-4220.02, 257.54, 1659.79], KorokType.OfferBanana, "");
		this.addKorok("W05", [-4266.57, 226.05, 1691.53], KorokType.OfferBanana, "");
		this.addKorok("W06", [-4353.38, 242.21, 1838.52], KorokType.FlowerChase, "");
		this.addKorok("W07", [-2066.65, 228.32, 1850.09], KorokType.BlockPuzzle, "");
		this.addKorok("W08", [-2417.31, 191.19, 1923.72], KorokType.Confetti, "In crack");
		this.addKorok("W09", [-4685.21, 171.46, 1970.38], KorokType.Confetti, "Highest fin on skeleton");
		this.addKorok("W10", [-2550.17, 190.00, 2038.10], KorokType.Balloon, "");
		this.addKorok("W11", [-2167.78, 336.44, 2082.04], KorokType.LiftRockTree, "");
		this.addKorok("W12", [-2264.51, 356.98, 2163.37], KorokType.AcornHanging, "");
		this.addKorok("W13", [-4710.48, 209.71, 2171.83], KorokType.Confetti, "Highest fin on skeleton");
		this.addKorok("W14", [-2018.63, 188.73, 2186.64], KorokType.LiftRock, "End of bridge");
		this.addKorok("W15", [-2815.31, 160.71, 2247.91], KorokType.LiftRock, "On pillar");
		this.addKorok("W16", [-2259.54, 357.45, 2224.75], KorokType.BoulderGolf, "");
		this.addKorok("W17", [-3353.03, 156.91, 2276.30], KorokType.BlockPuzzle, "");
		this.addKorok("W18", [-4607.90, 161.60, 2338.84], KorokType.Confetti, "Top of skeleton (pinwheel)");
		this.addKorok("W19", [-2853.36, 210.49, 2320.15], KorokType.LiftRock, "");
		this.addKorok("W20", [-1658.58, 159.11, 2306.77], KorokType.Confetti, "In tree");
		this.addKorok("W21", [-1665.89, 143.88, 2338.16], KorokType.BlockPuzzle, "");
		this.addKorok("W22", [-1399.42, 220.20, 2378.20], KorokType.Balloon, "");
		this.addKorok("W23", [-2066.65, 288.81, 2397.04], KorokType.Race, "");
		this.addKorok("W24", [-2059.88, 306.77, 2420.09], KorokType.LiftRock, "On platform");
		this.addKorok("W25", [-4876.90, 153.29, 2469.67], KorokType.MatchCactus, "");
		this.addKorok("W26", [-1809.82, 162.51, 2459.62], KorokType.BlockPuzzle, "");
		this.addKorok("W27", [-1615.96, 193.37, 2447.32], KorokType.Balloon, "");
		this.addKorok("W28", [-4373.89, 165.13, 2587.05], KorokType.Race, "");
		this.addKorok("W29", [-3751.84, 157.35, 2531.62], KorokType.Race, "");
		this.addKorok("W30", [-2594.75, 284.72, 2539.86], KorokType.BlockPuzzle, "");
		this.addKorok("W31", [-2358.78, 476.12, 2554.53], KorokType.LiftRock, "");
		this.addKorok("W32", [-2305.08, 486.25, 2561.36], KorokType.BlockPuzzle, "");
		this.addKorok("W33", [-2207.69, 432.16, 2550.62], KorokType.Balloon, "Under bridge");
		this.addKorok("W34", [-1935.34, 452.79, 2673.50], KorokType.RockCircle, "");
		this.addKorok("W35", [-2628.11, 168.49, 2751.20], KorokType.Balloon, "Between statue arms");
		this.addKorok("W36", [-4224.86, 154.65, 2738.07], KorokType.MatchCactus, "");
		this.addKorok("W37", [-3107.27, 135.38, 2881.24], KorokType.FlowerChase, "On skeleton");
		this.addKorok("W38", [-2298.69, 352.56, 2850.02], KorokType.LiftRock, "On pillar");
		this.addKorok("W39", [-2019.69, 375.79, 2884.23], KorokType.BlockPuzzle, "");
		this.addKorok("W40", [-2685.72, 206.05, 2929.65], KorokType.LiftRock, "Top of statue");
		this.addKorok("W41", [-1465.90, 418.66, 2952.55], KorokType.LiftRock, "On overpass");
		this.addKorok("W42", [-3886.14, 229.43, 2965.82], KorokType.Confetti, "");
		this.addKorok("W43", [-3435.38, 145.89, 2980.16], KorokType.FlowerChase, "");
		this.addKorok("W44", [-2918.65, 141.70, 3061.01], KorokType.BlockPuzzle, "");
		this.addKorok("W45", [-1599.49, 307.14, 3099.93], KorokType.RockCircle, "");
		this.addKorok("W46", [-1538.74, 278.40, 3070.08], KorokType.LiftRockRubble, "Corner");
		this.addKorok("W47", [-4195.74, 165.61, 3091.55], KorokType.FlowerChase, "Right side of skeleton");
		this.addKorok("W48", [-2172.50, 185.72, 3200.39], KorokType.BlockPuzzle, "");
		this.addKorok("W49", [-1926.30, 264.63, 3241.99], KorokType.BlockPuzzle, "");
		this.addKorok("W50", [-1922.22, 332.97, 3279.69], KorokType.LiftRock, "Top of big pillar");
		this.addKorok("W51", [-4862.19, 129.35, 3260.96], KorokType.MatchCactus, "");
		this.addKorok("W52", [-2636.19, 101.81, 3302.57], KorokType.BlockPuzzle, "Inside skull");
		this.addKorok("W53", [-1379.17, 592.90, 3328.08], KorokType.LiftRock, "On pillar");
		this.addKorok("W54", [-1526.97, 397.51, 3405.89], KorokType.LiftRock, "");
		this.addKorok("W55", [-4307.61, 132.16, 3459.74], KorokType.LiftRock, "On a rock");
		this.addKorok("W56", [-3799.64, 128.15, 3428.88], KorokType.LiftRock, "");
		this.addKorok("W57", [-3728.54, 166.48, 3610.08], KorokType.Balloon, "On tree in oasis");
		this.addKorok("W58", [-1996.60, 301.55, 3619.66], KorokType.Race, "");
		this.addKorok("W59", [-1611.51, 411.29, 3678.32], KorokType.IceBlock, "");
		this.addKorok("W60", [-1357.03, 479.65, 3671.94], KorokType.IceBlock, "");
		this.addKorok("W61", [-4347.89, 142.88, 3713.18], KorokType.MatchCactus, "");
		this.addKorok("W62", [-3031.62, 181.00, 3754.59], KorokType.RockCircle, "");
		this.addKorok("W63", [-2448.41, 134.73, 3751.93], KorokType.Race, "");
		this.addKorok("W64", [-1351.45, 448.56, 3770.79], KorokType.RockCircle, "");
		this.addKorok("W65", [-4859.17, 172.69, 3832.94], KorokType.Confetti, "Top of skeleton");
		this.addKorok("W66", [-4160.32, 133.13, 3826.20], KorokType.LiftRock, "");
		this.addKorok("W67", [-3286.43, 169.88, 3787.15], KorokType.MatchCactus, "");
		this.addKorok("W68", [-2547.42, 141.43, 3951.93], KorokType.LiftRock, "On pillar");
		this.addKorok("X01", [-308.77, 132.64, -1166.00], KorokType.LiftRock, "On ledge");
		this.addKorok("X02", [-147.51, 188.97, -1159.79], KorokType.LilyPads, "");
		this.addKorok("X03", [-265.73, 250.36, -1138.75], KorokType.LiftRockRubble, "");
		this.addKorok("X04", [-378.65, 163.60, -1112.09], KorokType.Balloon, "");
		this.addKorok("X05", [-250.14, 432.88, -1061.67], KorokType.Confetti, "Castle spire");
		this.addKorok("X06", [-251.84, 517.16, -1061.77], KorokType.Balloon, "");
		this.addKorok("X07", [-383.20, 347.46, -993.62], KorokType.Confetti, "Spire of Zelda's Study");
		this.addKorok("X08", [-368.95, 151.43, -993.44], KorokType.LiftRock, "On wooden platform");
		this.addKorok("X09", [-335.40, 256.56, -1003.31], KorokType.LiftRock, "");
		this.addKorok("X10", [-254.0, 364.01, -994.61], KorokType.Race, "Glide");
		this.addKorok("X11", [-100.92, 131.37, -979.74], KorokType.IceBlock, "");
		this.addKorok("X12", [-68.06, 261.28, -979.62], KorokType.LiftRock, "");
		this.addKorok("X13", [-436.57, 117.51, -932.82], KorokType.OfferEgg, "");
		this.addKorok("X14", [-270.26, 255.10, -879.19], KorokType.LiftRockRubble, "");
		this.addKorok("X15", [-168.36, 216.01, -860.40], KorokType.AcornHanging, "Behind wall");
		this.addKorok("X16", [-162.05, 310.19, -878.05], KorokType.Confetti, "Spire of Second Gatehouse");
		this.addKorok("X17", [-353.38, 286.36, -829.60], KorokType.Confetti, "Spire of First Gatehouse");
		this.addKorok("X18", [-394.02, 204.57, -785.40], KorokType.LiftRock, "On wall");
		this.addKorok("X19", [-324.38, 185.61, -798.15], KorokType.AcornHanging, "Behind wall");
		this.addKorok("X20", [-163.28, 156.12, -784.67], KorokType.LiftRockRubble, "");
		this.addKorok("X21", [-254.00, 210.54, -739.54], KorokType.ShootEmblem, "");
		this.addKorok("X22", [-247.16, 211.84, -739.61], KorokType.Balloon, "");
		this.addKorok("X23", [-234.59, 233.97, -743.93], KorokType.Balloon, "");
		this.addKorok("X24", [-253.85, 172.90, -632.22], KorokType.LiftRock, "On gate");
		this.addKorok("X25", [-230.68, 148.01, -633.37], KorokType.FlowerChase, "Across bridge");
		this.addKorok("Z01", [4707.05, 292.62, -1355.01], KorokType.FlowerChase, "");
		this.addKorok("Z02", [1398.03, 125.98, -856.34], KorokType.Race, "");
		this.addKorok("Z03", [1404.95, 122.09, -832.98], KorokType.LightChase, "");
		this.addKorok("Z04", [3208.67, 570.48, -1011.22], KorokType.LiftRock, "");
		this.addKorok("Z05", [3565.88, 530.63, -915.92], KorokType.LightChase, "");
		this.addKorok("Z06", [4702.88, 297.25, -1039.33], KorokType.LiftRock, "On wood platform");
		this.addKorok("Z07", [4698.90, 345.46, -1003.02], KorokType.LiftRock, "On pillar");
		this.addKorok("Z08", [3893.50, 549.96, -704.23], KorokType.LiftRockRubble, "");
		this.addKorok("Z09", [4616.44, 366.46, -638.02], KorokType.LilyPads, "");
		this.addKorok("Z10", [4697.83, 335.70, -547.03], KorokType.LiftRockRubble, "");
		this.addKorok("Z11", [1104.01, 210.75, -444.23], KorokType.Balloon, "In tree");
		this.addKorok("Z12", [1632.92, 119.0, -545.19], KorokType.LilyPads, "");
		this.addKorok("Z13", [1832.72, 217.95, -535.69], KorokType.Race, "");
		this.addKorok("Z14", [2427.76, 301.40, -412.12], KorokType.Confetti, "Top of tree");
		this.addKorok("Z15", [2791.46, 451.49, -500.93], KorokType.BlockPuzzle, "");
		this.addKorok("Z16", [3323.91, 301.62, -519.51], KorokType.Confetti, "");
		this.addKorok("Z17", [3321.49, 414.09, -514.25], KorokType.Confetti, "Top of Zora's Domain");
		this.addKorok("Z18", [3520.64, 326.72, -383.58], KorokType.BlockPuzzle, "");
		this.addKorok("Z19", [4071.79, 483.77, -459.05], KorokType.BoulderGolf, "Metal boulder");
		this.addKorok("Z20", [4136.60, 521.71, -408.56], KorokType.FlowerChase, "");
		this.addKorok("Z21", [1088.53, 164.78, -215.89], KorokType.RockCircle, "Near water");
		this.addKorok("Z22", [2517.50, 182.50, -212.48], KorokType.LiftRock, "On hill");
		this.addKorok("Z23", [2746.14, 237.58, -176.40], KorokType.Well, "");
		this.addKorok("Z24", [3083.23, 363.92, -167.68], KorokType.FlowerChase, "");
		this.addKorok("Z25", [3291.97, 295.38, -261.12], KorokType.LiftRock, "On ledge");
		this.addKorok("Z26", [3527.28, 395.30, -276.76], KorokType.LiftRock, "Peak");
		this.addKorok("Z27", [4080.40, 421.47, -216.78], KorokType.BlockPuzzle, "");
		this.addKorok("Z28", [4232.73, 385.99, -212.33], KorokType.LiftRockRubble, "");
		this.addKorok("Z29", [1185.50, 164.25, -67.41], KorokType.RockCircle, "");
		this.addKorok("Z30", [1387.27, 135.21, -112.92], KorokType.RockCircle, "");
		this.addKorok("Z31", [2665.53, 205.78, -1.29], KorokType.Basketball, "");
		this.addKorok("Z32", [3122.88, 185.60, -17.39], KorokType.RockCircle, "Below bridge");
		this.addKorok("Z33", [3206.76, 312.25, 23.68], KorokType.LightChase, "");
		this.addKorok("Z34", [3303.74, 393.35, 22.58], KorokType.LiftRock, "Peak");
		this.addKorok("Z35", [4134.86, 294.93, 60.32], KorokType.LiftRockRubble, "");
		this.addKorok("Z36", [4294.82, 171.30, 78.50], KorokType.LiftRockRubble, "");
		this.addKorok("Z37", [883.07, 133.61, 274.79], KorokType.LiftRock, "Middle of thorns");
		this.addKorok("Z38", [1434.73, 117.50, 135.40], KorokType.Well, "");
		this.addKorok("Z39", [1593.18, 115.98, 114.16], KorokType.LightChase, "");
		this.addKorok("Z40", [1736.36, 129.04, 85.67], KorokType.AcornHanging, "From tree");
		this.addKorok("Z41", [2085.78, 129.78, 181.66], KorokType.RockCircle, "");
		this.addKorok("Z42", [2769.94, 123.21, 348.84], KorokType.Balloon, "");
		this.addKorok("Z43", [2927.66, 292.94, 332.80], KorokType.BlockPuzzle, "");
		this.addKorok("Z44", [3140.85, 220.79, 324.96], KorokType.LightChase, "");
		this.addKorok("Z45", [3854.31, 150.81, 352.25], KorokType.LightChase, "");
		this.addKorok("Z46", [4144.43, 138.33, 195.96], KorokType.BlockPuzzle, "");
		this.addKorok("Z47", [1116.14, 119.93, 555.57], KorokType.LiftRockBoulder, "");
		this.addKorok("Z48", [1313.97, 115.90, 503.06], KorokType.LightChase, "");
		this.addKorok("Z49", [1446.49, 116.03, 558.36], KorokType.MatchTree, "");
		this.addKorok("Z50", [1685.60, 129.82, 661.12], KorokType.LightChase, "");
		this.addKorok("Z51", [3271.14, 105.92, 656.82], KorokType.RockCircle, "");
		this.addKorok("Z52", [3431.14, 146.40, 523.74], KorokType.LiftRockLeaves, "");
		this.addKorok("Z53", [3451.99, 143.56, 550.79], KorokType.FlowerChase, "In cave");
		this.addKorok("Z54", [3561.94, 106.24, 626.31], KorokType.LiftRockRubble, "");
		this.addKorok("Z55", [3721.26, 108.24, 617.62], KorokType.LiftRock, "");
		this.addKorok("Z56", [4199.45, 107.14, 463.67], KorokType.Basketball, "On stone");
		this.addKorok("Z57", [4264.45, 126.76, 628.22], KorokType.LiftRockSlab, "");
		this.addKorok("Z58", [4452.65, 164.26, 483.97], KorokType.Balloon, "In cave");
		this.addKorok("Z59", [4572.53, 134.99, 647.89], KorokType.FlowerChase, "Start in forest");
		this.addKorok("Z60", [1595.75, 117.39, 807.67], KorokType.OfferApple, "In cave");
		this.addKorok("Z61", [4499.12, 123.24, 1015.01], KorokType.FlowerChase, "");
		this.addKorok("Z62", [4533.43, 111.22, 1022.98], KorokType.RockCircle, "");
		// Add korok movements
		// GENERATED CODE
		this.addMovement("A05", 3170.78, -3317.52);
		this.addMovement("A14", 4252.40, -3134.47);
		this.addMovement("A28", 4069.00, -2462.26);
		this.addMovement("A44", 4349.08, -1496.86);
		this.addMovement("C27", -296.75, -261.92);
		this.addMovement("C73", -1173.58, 1166.04);
		this.addMovement("E14", 1610.18, -2544.72);
		this.addMovement("E17", 1915.68, -2382.10);
		this.addMovement("E25", 1527.35, -1887.45);
		this.addMovement("E33", 2330.11, -1486.41);
		this.addMovement("E45", 2374.46, -807.50);
		this.addMovement("F02", 1131.80, 2327.59);
		this.addMovement("F16", 1559.28, 2896.84);
		this.addMovement("F19", 1776.99, 3057.73);
		this.addMovement("G02", -4207.97, 266.73);
		this.addMovement("G05", -4276.29, 610.64);
		this.addMovement("G11", -4388.92, 1218.42);
		this.addMovement("G32", -2395.78, 1915.51);
		this.addMovement("G35", -2603.74, 2126.61);
		this.addMovement("H07", -4251.77, -3590.21);
		this.addMovement("H49", -2941.33, -2483.43);
		this.addMovement("H73", -2772.74, -1472.73);
		this.addMovement("L08", -668.85, 2392.43);
		this.addMovement("L47", -276.76, 3316.47);
		this.addMovement("L65", 546.27, 3580.35);
		this.addMovement("L72", 894.81, 3476.66);
		this.addMovement("L80", -597.87, 3845.34);
		this.addMovement("N17", 3439.15, 1100.78);
		this.addMovement("N32", 4079.84, 1716.65);
		this.addMovement("N39", 3735.97, 1686.96);
		this.addMovement("N66", 4016.93, 2971.36);
		this.addMovement("R09", -1892.06, -1235.05);
		this.addMovement("R18", -1984.92, -624.09);
		this.addMovement("R25", -1787.71, -377.97);
		this.addMovement("R26", -989.87, -541.01);
		this.addMovement("R30", -2458.83, -276.05);
		this.addMovement("R35", -3822.16, -170.91);
		this.addMovement("R62", -2187.60, 573.36);
		this.addMovement("T06", -4495.71, -2510.34);
		this.addMovement("T17", -3782.46, -1798.64);
		this.addMovement("T30", -3692.07, -792.07);
		this.addMovement("T34", -3018.71, -585.07);
		this.addMovement("T37", -3647.12, -436.34);
		this.addMovement("W23", -2103.93, 2297.19);
		this.addMovement("W28", -4384.57, 2575.82);
		this.addMovement("W29", -3757.10, 2445.96);
		this.addMovement("W58", -2018.28, 3437.19);
		this.addMovement("W63", -2591.20, 3543.36);
		this.addMovement("X10", -254.00, -1044.50);
		this.addMovement("Z02", 1585.26, -719.34);
		this.addMovement("Z13", 2089.33, -327.27);
		this.addMovement("A35", 4385.60, -1548.02);
		this.addMovement("A35", 4433.26, -1577.73);
		this.addMovement("A35", 4493.91, -1609.91);
		this.addMovement("A35", 4502.12, -1654.62);
		this.addMovement("A35", 4526.40, -1695.15);
		this.addMovement("A35", 4553.33, -1724.99);
		this.addMovement("A35", 4577.97, -1756.76);
		this.addMovement("A35", 4601.99, -1791.67);
		this.addMovement("A35", 4617.25, -1812.53);
		this.addMovement("A38", 4112.67, -1634.02);
		this.addMovement("A38", 4101.66, -1635.66);
		this.addMovement("A38", 4096.97, -1637.62);
		this.addMovement("A38", 4093.97, -1634.61);
		this.addMovement("C38", -1094.60, 196.73);
		this.addMovement("C38", -1105.74, 216.90);
		this.addMovement("C38", -1101.07, 233.77);
		this.addMovement("C38", -1125.37, 238.33);
		this.addMovement("C63", -587.14, 875.57);
		this.addMovement("C63", -601.66, 860.79);
		this.addMovement("C63", -616.98, 861.43);
		this.addMovement("C63", -632.29, 873.98);
		this.addMovement("D36", 468.27, 1717.43);
		this.addMovement("D36", 475.17, 1749.54);
		this.addMovement("D36", 499.28, 1783.29);
		this.addMovement("D36", 506.46, 1803.81);
		this.addMovement("D39", 981.38, 1837.23);
		this.addMovement("D39", 995.80, 1836.55);
		this.addMovement("D39", 1006.34, 1828.51);
		this.addMovement("D39", 1033.92, 1807.87);
		this.addMovement("F10", 1378.68, 2767.80);
		this.addMovement("F10", 1361.45, 2758.80);
		this.addMovement("F10", 1370.41, 2742.94);
		this.addMovement("F10", 1371.11, 2743.77);
		this.addMovement("F12", 2043.58, 2660.78);
		this.addMovement("F12", 2054.12, 2705.84);
		this.addMovement("F12", 2092.84, 2692.70);
		this.addMovement("F12", 2112.50, 2710.14);
		this.addMovement("F22", 2947.58, 3003.18);
		this.addMovement("F22", 2952.04, 2989.74);
		this.addMovement("F22", 2962.11, 2982.25);
		this.addMovement("F22", 2955.58, 2973.68);
		this.addMovement("F22", 2950.32, 2965.56);
		this.addMovement("F22", 2967.16, 2948.40);
		this.addMovement("F22", 2968.22, 2965.22);
		this.addMovement("F22", 2972.14, 2975.83);
		this.addMovement("F22", 2974.78, 2989.42);
		this.addMovement("F23", 1012.99, 3212.90);
		this.addMovement("F23", 1012.90, 3222.67);
		this.addMovement("F23", 999.86, 3234.49);
		this.addMovement("F23", 1007.53, 3247.78);
		this.addMovement("F28", 1737.64, 3204.04);
		this.addMovement("F28", 1754.08, 3198.55);
		this.addMovement("F28", 1766.46, 3195.10);
		this.addMovement("F28", 1788.81, 3210.60);
		this.addMovement("F45", 2947.30, 3436.00);
		this.addMovement("F45", 2948.65, 3435.43);
		this.addMovement("F45", 2950.21, 3434.97);
		this.addMovement("F45", 2942.35, 3435.77);
		this.addMovement("F50", 3079.72, 3643.24);
		this.addMovement("F50", 3079.72, 3643.24);
		this.addMovement("F50", 3093.29, 3659.51);
		this.addMovement("F50", 3082.66, 3705.24);
		this.addMovement("F50", 3061.24, 3658.99);
		this.addMovement("G08", -4381.95, 782.54);
		this.addMovement("H03", -4084.91, -3717.65);
		this.addMovement("H03", -4056.62, -3726.40);
		this.addMovement("H03", -4003.97, -3693.82);
		this.addMovement("H24", -4201.37, -3117.92);
		this.addMovement("H24", -4205.57, -3121.48);
		this.addMovement("H24", -4230.32, -3126.51);
		this.addMovement("H24", -4228.86, -3132.67);
		this.addMovement("H39", -4204.78, -2782.24);
		this.addMovement("H39", -4216.36, -2769.79);
		this.addMovement("H39", -4216.60, -2766.18);
		this.addMovement("H39", -4211.13, -2755.22);
		this.addMovement("H39", -4209.61, -2735.38);
		this.addMovement("H39", -4201.12, -2719.52);
		this.addMovement("H39", -4197.85, -2709.39);
		this.addMovement("H39", -4196.75, -2699.01);
		this.addMovement("H39", -4197.70, -2689.47);
		this.addMovement("H56", -2723.18, -2263.21);
		this.addMovement("H56", -2729.82, -2264.78);
		this.addMovement("H56", -2737.84, -2263.83);
		this.addMovement("H56", -2742.96, -2258.24);
		this.addMovement("H70", -2376.80, -1790.90);
		this.addMovement("H70", -2370.25, -1778.33);
		this.addMovement("H70", -2370.82, -1764.25);
		this.addMovement("H70", -2359.08, -1757.90);
		this.addMovement("L05", 316.09, 2161.86);
		this.addMovement("L05", 334.36, 2161.11);
		this.addMovement("L05", 358.60, 2164.08);
		this.addMovement("L05", 376.94, 2150.91);
		this.addMovement("L30", -5.81, 2580.12);
		this.addMovement("L30", -11.36, 2577.65);
		this.addMovement("L30", -5.38, 2584.10);
		this.addMovement("L30", -10.70, 2581.50);
		this.addMovement("L55", 693.88, 3059.19);
		this.addMovement("L55", 678.99, 3061.20);
		this.addMovement("L55", 678.70, 3088.62);
		this.addMovement("L55", 698.36, 3081.73);
		this.addMovement("L64", -209.34, 3444.94);
		this.addMovement("L64", -210.17, 3399.51);
		this.addMovement("L64", -205.12, 3370.46);
		this.addMovement("L64", -217.37, 3353.34);
		this.addMovement("N10", 2308.26, 750.53);
		this.addMovement("N10", 2308.80, 731.38);
		this.addMovement("N10", 2306.25, 715.55);
		this.addMovement("N10", 2292.73, 701.58);
		this.addMovement("N44", 4131.11, 2002.00);
		this.addMovement("N44", 4142.23, 2008.30);
		this.addMovement("N44", 4151.46, 1992.49);
		this.addMovement("N44", 4173.42, 1980.35);
		this.addMovement("N44", 4190.38, 1984.68);
		this.addMovement("N44", 4200.09, 1979.95);
		this.addMovement("N44", 4213.94, 1979.82);
		this.addMovement("N44", 4229.49, 1983.12);
		this.addMovement("N44", 4246.43, 1983.63);
		this.addMovement("N57", 4594.88, 2520.44);
		this.addMovement("N57", 4622.89, 2470.21);
		this.addMovement("N57", 4632.42, 2436.13);
		this.addMovement("N57", 4651.31, 2412.54);
		this.addMovement("N59", 3372.41, 2301.17);
		this.addMovement("N59", 3391.55, 2305.14);
		this.addMovement("N59", 3420.12, 2319.36);
		this.addMovement("N59", 3444.12, 2342.22);
		this.addMovement("N59", 3459.52, 2344.00);
		this.addMovement("N59", 3470.77, 2347.60);
		this.addMovement("N59", 3468.32, 2364.61);
		this.addMovement("N59", 3453.78, 2384.80);
		this.addMovement("N59", 3440.93, 2392.30);
		this.addMovement("N62", 4126.20, 2542.35);
		this.addMovement("N62", 4116.05, 2561.58);
		this.addMovement("N62", 4132.32, 2563.09);
		this.addMovement("N62", 4137.83, 2575.74);
		this.addMovement("P04", -997.50, 1660.79);
		this.addMovement("P04", -988.46, 1650.88);
		this.addMovement("P04", -979.80, 1646.63);
		this.addMovement("P04", -978.50, 1633.33);
		this.addMovement("R10", -1234.31, -1094.26);
		this.addMovement("R10", -1265.71, -1047.65);
		this.addMovement("R10", -1272.40, -1035.54);
		this.addMovement("R10", -1235.62, -1004.94);
		this.addMovement("R23", -2052.12, -507.62);
		this.addMovement("R23", -2052.09, -495.07);
		this.addMovement("R23", -2055.43, -483.19);
		this.addMovement("R23", -2031.36, -506.91);
		this.addMovement("R45", -1181.30, -237.17);
		this.addMovement("R45", -1195.48, -233.79);
		this.addMovement("R45", -1194.82, -236.62);
		this.addMovement("R45", -1172.15, -232.36);
		this.addMovement("R61", -2288.86, 413.65);
		this.addMovement("R61", -2281.03, 430.56);
		this.addMovement("R61", -2263.90, 440.50);
		this.addMovement("R61", -2257.35, 452.47);
		this.addMovement("T01", -4063.42, -2285.92);
		this.addMovement("T01", -4048.42, -2299.71);
		this.addMovement("T01", -4043.07, -2307.40);
		this.addMovement("T01", -4031.27, -2322.24);
		this.addMovement("T16", -3973.11, -1695.00);
		this.addMovement("T16", -3968.90, -1671.43);
		this.addMovement("T16", -4015.77, -1684.29);
		this.addMovement("T16", -4044.05, -1686.62);
		this.addMovement("W06", -4349.50, 1828.01);
		this.addMovement("W06", -4345.34, 1832.49);
		this.addMovement("W06", -4353.39, 1834.54);
		this.addMovement("W06", -4352.04, 1826.04);
		this.addMovement("W37", -3063.65, 2905.87);
		this.addMovement("W43", -3466.84, 2956.78);
		this.addMovement("W43", -3498.91, 2952.55);
		this.addMovement("W43", -3480.76, 2986.11);
		this.addMovement("W43", -3469.87, 2926.08);
		this.addMovement("W47", -4171.26, 3058.97);
		this.addMovement("W47", -4174.61, 3065.55);
		this.addMovement("W47", -4186.84, 3064.50);
		this.addMovement("W47", -4190.10, 3083.78);
		this.addMovement("X25", -257.07, -589.49);
		this.addMovement("X25", -250.14, -583.87);
		this.addMovement("X25", -246.70, -579.26);
		this.addMovement("X25", -243.03, -585.36);
		this.addMovement("X25", -237.45, -584.97);
		this.addMovement("X25", -237.48, -597.91);
		this.addMovement("X25", -237.52, -610.94);
		this.addMovement("X25", -233.49, -624.32);
		this.addMovement("Z01", 4668.77, -1290.65);
		this.addMovement("Z01", 4661.62, -1313.48);
		this.addMovement("Z01", 4667.06, -1330.71);
		this.addMovement("Z01", 4683.88, -1340.37);
		this.addMovement("Z20", 4083.13, -424.81);
		this.addMovement("Z20", 4114.89, -438.15);
		this.addMovement("Z20", 4153.29, -436.52);
		this.addMovement("Z20", 4151.59, -415.25);
		this.addMovement("Z24", 2984.44, -177.12);
		this.addMovement("Z24", 3014.53, -182.23);
		this.addMovement("Z24", 3031.13, -168.81);
		this.addMovement("Z24", 3048.19, -165.46);
		this.addMovement("Z24", 3059.07, -158.30);
		this.addMovement("Z24", 3068.42, -157.76);
		this.addMovement("Z24", 3081.95, -160.38);
		this.addMovement("Z24", 3090.17, -160.65);
		this.addMovement("Z24", 3088.52, -162.44);
		this.addMovement("Z53", 3509.61, 519.34);
		this.addMovement("Z53", 3488.92, 536.12);
		this.addMovement("Z53", 3492.29, 560.71);
		this.addMovement("Z53", 3471.08, 547.40);
		this.addMovement("Z59", 4483.81, 637.03);
		this.addMovement("Z59", 4497.50, 637.89);
		this.addMovement("Z59", 4517.59, 630.68);
		this.addMovement("Z59", 4544.74, 640.40);
		this.addMovement("Z61", 4512.28, 980.84);
		this.addMovement("Z61", 4526.36, 1009.01);
		this.addMovement("Z61", 4502.82, 1016.54);
		this.addMovement("Z61", 4473.22, 1010.40);
		this.addMovement("A04", 3804.21, -3422.34);
		this.addMovement("A04", 3801.09, -3416.84);
		this.addMovement("A04", 3803.85, -3404.50);
		this.addMovement("A04", 3809.32, -3418.87);
		this.addMovement("A17", 4733.06, -3122.01);
		this.addMovement("A17", 4745.81, -3117.43);
		this.addMovement("A17", 4763.11, -3145.45);
		this.addMovement("A17", 4802.33, -3121.14);
		this.addMovement("A19", 4101.37, -2837.74);
		this.addMovement("A19", 4107.09, -2832.10);
		this.addMovement("A19", 4097.62, -2822.57);
		this.addMovement("A19", 4097.38, -2834.17);
		this.addMovement("A48", 4094.11, -1339.99);
		this.addMovement("A48", 4094.13, -1345.63);
		this.addMovement("A48", 4101.06, -1340.05);
		this.addMovement("A48", 4099.96, -1346.45);
		this.addMovement("C50", 145.26, 299.62);
		this.addMovement("C50", 150.82, 299.21);
		this.addMovement("C50", 136.76, 299.28);
		this.addMovement("C50", 145.50, 314.29);
		this.addMovement("D43", -199.53, 1847.93);
		this.addMovement("D43", -183.77, 1831.25);
		this.addMovement("D43", -183.45, 1841.59);
		this.addMovement("D43", -202.26, 1835.15);
		this.addMovement("L28", 521.61, 2487.43);
		this.addMovement("L28", 526.36, 2486.59);
		this.addMovement("L28", 520.99, 2480.93);
		this.addMovement("L28", 528.06, 2479.40);
		this.addMovement("R41", -1660.04, -43.69);
		this.addMovement("R41", -1650.71, -28.74);
		this.addMovement("R41", -1660.27, -31.71);
		this.addMovement("R41", -1643.74, -40.21);
		this.addMovement("R46", -3465.07, 164.86);
		this.addMovement("R46", -3469.73, 171.52);
		this.addMovement("R46", -3455.94, 168.63);
		this.addMovement("R46", -3467.92, 156.08);
		this.addMovement("R65", -2471.45, 541.77);
		this.addMovement("R65", -2461.44, 551.94);
		this.addMovement("R65", -2476.09, 558.82);
		this.addMovement("R65", -2471.50, 551.84);
		this.addMovement("T36", -4041.84, -344.25);
		this.addMovement("T36", -4038.30, -356.02);
		this.addMovement("T36", -4035.56, -343.73);
		this.addMovement("T36", -4046.39, -349.59);
	}

	public recognizes(name: string): boolean {
		return name.startsWith("_Korok::") && name.substring(8) in this.map;
	}
	public compile(typedString: TypedString): RouteAssembly | undefined {
		const content = typedString.toString();
		if (!content.startsWith("_Korok::")){
			return undefined;
		}
		const id = content.substring(8);
		if(!(id in this.map)){
			return undefined;
		}
		return this.map[id]();
	}

	private addKorok(id: string,  coord: [number, number, number], type: KorokType, comment: string): void{
		this.map[id] = () => ({
			text: new TypedStringSingle({
				content: id+" "+type,
				type: StringType.Npc
			}),
			comment: StringParser.parseStringBlockSimple(comment),
			icon: mapKorokToImage(type),
			splitType: SplitType.Korok,
			movements: [{
				to: {x: coord[0], z: coord[2]},
				isAway: false,
				isWarp: false,
			}],
			timeOverride: mapKorokToEstimate(type),

		});
	}

	private addMovement(id: string, x: number, z: number): void {
		const oldGenerator = this.map[id];
		this.map[id] = ()=>{
			const korok = oldGenerator();
			korok.movements?.splice(-1,0,{to: {x, z},
				isAway: false,
				isWarp: false
			});
			return korok;
		};
	}

}

const mapKorokToImage = (korok: KorokType):string =>{
	switch(korok){
		// Korok images (generated)
		// GENERATE CODE
		case KorokType.Acorn: return "korok-acorn";
		case KorokType.AcornFlying: return "korok-acorn-flying";
		case KorokType.AcornLog: return "korok-acorn";
		case KorokType.AcornTree: return "korok-acorn";
		case KorokType.AcornHanging: return "korok-acorn";
		case KorokType.Apple: return "korok";
		case KorokType.Balloon: return "korok-balloon";
		case KorokType.Basketball: return "korok-basketball";
		case KorokType.Beard: return "korok";
		case KorokType.BlockPuzzle: return "korok-magnesis";
		case KorokType.BoulderCircle: return "korok-rock-circle";
		case KorokType.BoulderGolf: return "korok-golf-boulder";
		case KorokType.Confetti: return "korok-confetti";
		case KorokType.FlowerChase: return "korok-flower";
		case KorokType.FlowerCount: return "korok-flower";
		case KorokType.IceBlock: return "korok-ice";
		case KorokType.JumpFence: return "korok";
		case KorokType.LiftRock: return "korok-rock";
		case KorokType.LiftRockDoor: return "korok-magnesis";
		case KorokType.LiftRockTree: return "korok-rock-tree";
		case KorokType.LiftRockRubble: return "korok-rock-under";
		case KorokType.LiftRockBoulder: return "korok-rock-under";
		case KorokType.LiftRockLeaves: return "korok-rock-under";
		case KorokType.LiftRockSlab: return "korok-rock-under";
		case KorokType.LightChase: return "korok-light-chase";
		case KorokType.LilyPads: return "korok-lily";
		case KorokType.LuminousStone: return "korok";
		case KorokType.MatchTree: return "korok-matching";
		case KorokType.MatchCactus: return "korok-matching";
		case KorokType.MetalBoxCircle: return "korok-magnesis";
		case KorokType.OfferApple: return "korok-offer-apple";
		case KorokType.OfferBanana: return "korok-offer-banana";
		case KorokType.OfferDurian: return "korok-offer-durian";
		case KorokType.OfferEgg: return "korok-offer-egg";
		case KorokType.OfferPalmFruit: return "korok-offer-durian";
		case KorokType.OfferPepper: return "korok-offer-apple";
		case KorokType.OfferShield: return "korok-offer-apple";
		case KorokType.Race: return "korok-race";
		case KorokType.RockCircle: return "korok-rock-circle";
		case KorokType.ShootEmblem: return "korok-shoot";
		case KorokType.SnowballGolf: return "korok-golf-snowball";
		case KorokType.Torch: return "korok";
		case KorokType.TreeBranch: return "korok";
		case KorokType.TreeStump: return "korok-magnesis";
		case KorokType.Well: return "korok-magnesis";
		case KorokType.Other: return "korok";
		default: return "korok";
	}
};

const mapKorokToEstimate = (korok: KorokType):number =>{
	switch(korok){
		// Korok time estimate (generated)
		// GENERATE CODE
		case KorokType.Acorn: return 5;
		case KorokType.AcornFlying: return 5;
		case KorokType.AcornLog: return 5;
		case KorokType.AcornTree: return 5;
		case KorokType.AcornHanging: return 5;
		case KorokType.Apple: return 4;
		case KorokType.Balloon: return 10;
		case KorokType.Basketball: return 8;
		case KorokType.Beard: return 2;
		case KorokType.BlockPuzzle: return 10;
		case KorokType.BoulderCircle: return 10;
		case KorokType.BoulderGolf: return 5;
		case KorokType.Confetti: return 5;
		case KorokType.FlowerChase: return 12;
		case KorokType.FlowerCount: return 10;
		case KorokType.IceBlock: return 10;
		case KorokType.JumpFence: return 15;
		case KorokType.LiftRock: return 2;
		case KorokType.LiftRockDoor: return 3;
		case KorokType.LiftRockTree: return 5;
		case KorokType.LiftRockRubble: return 3;
		case KorokType.LiftRockBoulder: return 3;
		case KorokType.LiftRockLeaves: return 3;
		case KorokType.LiftRockSlab: return 4;
		case KorokType.LightChase: return 5;
		case KorokType.LilyPads: return 5;
		case KorokType.LuminousStone: return 2;
		case KorokType.MatchTree: return 5;
		case KorokType.MatchCactus: return 5;
		case KorokType.MetalBoxCircle: return 10;
		case KorokType.OfferApple: return 3;
		case KorokType.OfferBanana: return 3;
		case KorokType.OfferDurian: return 3;
		case KorokType.OfferEgg: return 3;
		case KorokType.OfferPalmFruit: return 3;
		case KorokType.OfferPepper: return 3;
		case KorokType.OfferShield: return 3;
		case KorokType.Race: return 15;
		case KorokType.RockCircle: return 10;
		case KorokType.ShootEmblem: return 5;
		case KorokType.SnowballGolf: return 10;
		case KorokType.Torch: return 5;
		case KorokType.TreeBranch: return 1;
		case KorokType.TreeStump: return 8;
		case KorokType.Well: return 8;
		case KorokType.Other: return 5;
		default: return 5;
	}
};

export default new KorokModule();
