import { ComputeStyleInputs } from "ui/styles";

export const TypedStringComponentStyle =({colors}: ComputeStyleInputs)=> <const>{
	stringItemColor: {
		color: colors.stringItemColor
	},
	stringLocationColor: {
		color: colors.stringLocationColor
	},
	stringNpcColor: {
		color: colors.stringNpcColor
	},
	stringRuneColor: {
		color: colors.stringRuneColor
	},
	stringBossColor: {
		color: colors.stringBossColor
	},
	stringEnemyColor: {
		color: colors.stringEnemyColor
	},
	stringDirectionColor: {
		color: colors.stringDirectionColor
	},
	stringLinkColor: {
		color: colors.stringLinkColor
	},
	stringVariableColor: {
		color: colors.stringVariableColor
	},
	stringImportantColor: {
		color: colors.stringImportantColor
	},
	stringGaleColor: {
		backgroundColor: colors.stringGaleColor,
		color: "#000000"
	},
	stringFuryColor: {
		backgroundColor: colors.stringFuryColor,
		color: "#000000"
	},

	stringItemColorNotes: {
		color: colors.stringItemColorNotes
	},
	stringLocationColorNotes: {
		color: colors.stringLocationColorNotes
	},
	stringNpcColorNotes: {
		color: colors.stringNpcColorNotes
	},
	stringRuneColorNotes: {
		color: colors.stringRuneColorNotes
	},
	stringBossColorNotes: {
		color: colors.stringBossColorNotes
	},
	stringEnemyColorNotes: {
		color: colors.stringEnemyColorNotes
	},
	stringDirectionColorNotes: {
		color: colors.stringDirectionColorNotes
	},
	stringLinkColorNotes: {
		color: colors.stringLinkColorNotes
	},
	stringVariableColorNotes: {
		color: colors.stringVariableColorNotes
	},
	stringImportantColorNotes: {
		color: colors.stringImportantColorNotes
	},
	stringGaleColorNotes: {
		color: colors.stringGaleColorNotes
	},
	stringFuryColorNotes: {
		color: colors.stringFuryColorNotes
	},
};
